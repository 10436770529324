import instance from '../instance';
import { Season } from '../seasons';

export interface Variant {
  id: string;
  title: string;
  image: string;
  quantity: number;
  price: string;
}

export interface Reward {
  id: string;
  title: string;
  rewardId: string;
  price: number;
  discountPercentage: number;
  image: string;
  variants: Variant[];
}

export interface RewardWithVariantId extends Reward {
  variantId: string;
}

export interface PlayerTier {
  id: string;
  name: string;
  topPercentage: number;
  rewards: Reward[];
}

export interface RedeemableRewards {
  season: Season;
  tier: PlayerTier;
}

export interface PlayerTierList {
  tier: PlayerTier;
  isCurrent: boolean;
}

export interface FutureRewards {
  season: Season;
  tiers: PlayerTierList[];
}

export interface CartReward {
  id: string;
  rewardId: string;
  variantId: string;
}

export async function listPlayerRewards() {
  return instance.get<{ rewards: Reward[] }>('/rewards');
}

export async function listCurrentPlayerRewards() {
  return instance.get<{
    redeemableRewards: RedeemableRewards;
    futureRewards: FutureRewards;
  }>('/rewards/current');
}

export async function redeemCurrentPlayerRewards(seasonId: number) {
  return instance.post<{ invoiceUrl: string }>(`/rewards/redeem/${seasonId}`);
}

export async function getPlayerCart(seasonId: number) {
  return instance.get<{ rewards: RewardWithVariantId[], invoiceUrl: string, status: string }>(`/rewards/cart/${seasonId}`);
}

export async function updatePlayerCart({rewards, seasonId}: {rewards: { rewardId: string, variantId: string }[], seasonId: number}) {
  return instance.post<{ rewards: RewardWithVariantId[] }>(`/rewards/cart/${seasonId}`, { rewards });
}

import React, { useCallback } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useAPI } from '../../../utils/hooks/useRequest';
import api from '../../../api';
import useInput from '../../../utils/hooks/useInput';

const BonusKeys: React.FC = () => {
  const [goldenKeysToGive, setGoldenKeysToGive] = useInput('0');

  const { state: sendGoldenKeysState, fetch: sendGoldenKeysFetch } = useAPI<{}, number>(
    api.goldenKeys.sendGoldenKeys,
  );

  const sendGoldenKeysHandler = useCallback(() => {
    sendGoldenKeysFetch(Number(goldenKeysToGive));
  }, [goldenKeysToGive, sendGoldenKeysFetch]);

  return (
    <>
      <Col>
        <Row>
          <Form.Group>
            <Form.Label style={{ color: 'white' }}>
              Number of golden keys to send
            </Form.Label>
            <Form.Control
              id='goldenKeysCount'
              type='number'
              name='goldenKeysCount'
              value={goldenKeysToGive}
              min={0}
              onChange={setGoldenKeysToGive}
            />
          </Form.Group>
        </Row>
        <Row>
          <Button onClick={sendGoldenKeysHandler} variant='success'
                  disabled={sendGoldenKeysState.status === 'IN_PROGRESS'}
                  className='mt-2 mb-3'>
            {sendGoldenKeysState.status === 'IN_PROGRESS' ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
                Loading...
              </>
            ) : (
              'Send Golden Keys To All Players'
            )}
          </Button>
        </Row>
        <Row className='mb-3'>
          {(sendGoldenKeysState.status === 'REJECTED') ? (
            <div style={{ color: 'red' }}>
              {sendGoldenKeysState.error.message}
            </div>
          ) : ''}
        </Row>
      </Col>
    </>
  );
};

export default BonusKeys;

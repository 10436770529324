module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"app-loading ")
    ; __append(escapeFn( locals.size ? `size-${locals.size}` : '' ))
    ; __append("\">\n    <div class=\"logo\"></div>\n    <svg class=\"spinner\" viewBox=\"25 25 50 50\">\n        <circle\n                class=\"path\"\n                cx=\"50\"\n                cy=\"50\"\n                r=\"20\"\n                fill=\"none\"\n                stroke-width=\"2\"\n                stroke-miterlimit=\"10\"\n        />\n    </svg>\n</div>\n")
  }
  return __output;

}
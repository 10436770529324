import instance from '../instance';

export interface Reward {
  rewardId_variantId: string;
  rewardId: string;
  variantId: string;
  stockToGive: number;
  chance: number;
  rewardTitle: string;
  rewardImage: string;
  variantTitle: string;
  totalStock: number;
}

export function getChances() {
  return instance.get<{
    winningChance: number,
    lossChance: number
  }>('/golden-keys/chances');
}

export function updateChances({ winningChance, lossChance }: {
  winningChance: number,
  lossChance: number
}) {
  return instance.post<{ status: 'ok' }>(
    '/golden-keys/chances',
    { winningChance, lossChance });
}

export function getRewards() {
  return instance.get<{
    rewards: Reward[],
    minMaxRollValue: number,
    maxRollValue: number
  }>('/golden-keys/rewards');
}

export function updateRewards({ rewards, maxRollValue }: {
  rewards: Omit<Reward,
    'rewardId_variantId'
    | 'rewardTitle'
    | 'rewardImage'
    | 'variantTitle'
    | 'totalStock' |
    'price'>[],
  maxRollValue: number
}) {
  return instance.post<{
    rewards: Reward[],
    minMaxRollValue: number,
    maxRollValue: number
  }>('/golden-keys/rewards', {
    rewards, maxRollValue,
  });
}

export function runTestRolls({ rewards, maxRollValue }: {
  rewards: Omit<Reward,
    'rewardId_variantId'
    | 'totalStock' |
    'price'>[],
  maxRollValue: number
}) {
  return instance.post<{
    rewards: (Omit<Reward,
      'rewardId_variantId'
      | 'totalStock' |
        'price' | 'stockToGive'
      | 'chance'>
      & { count: number })[],
    rollsCount: number
  }>
  ('/golden-keys/rewards/test', {
    rewards, maxRollValue,
  });
}

export function sendGoldenKeys(goldenKeysCount: number) {
  return instance.post<{ status: 'ok' }>('/golden-keys/bonus',
    { goldenKeysCount });
}

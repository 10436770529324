import { Dispatch } from 'redux';
import { Actions } from 'immer-reducer';

import { actions, AuthReducer } from './AuthReducer';
import api from '../../api';
import AuthService from '../../utils/auth/AuthService';

type AuthActions = Actions<typeof AuthReducer>;

export const fetchSelfUser = () => async (dispatch: Dispatch<AuthActions>) => {
  dispatch(actions.setUserPending());

  try {
    const res = await api.auth.fetchSelfUser();

    dispatch(actions.setUserFulfilled(res.data));
  } catch (e) {
    console.error(e);
    dispatch(actions.setUserRejected(e));
  }
};

export const logout = () => (dispatch: Dispatch<AuthActions>) => {
  AuthService.logout();
  dispatch(actions.reset());
};

export const refreshTokens = () => {
 return AuthService.refresh();
};

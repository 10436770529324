import instance from '../instance';

export type SelfUser = {
  UserName: string;
  IsActive: boolean;
  GameKey: string;
  GoldenKeys: number;
  PlayerRank: number;
  PlayerTier: string | undefined;
  DownloadLinkTypes: string[];
} & (
  | {
  IsTwitchConnected: false;
}
  | {
  IsTwitchConnected: true;
  TwitchData: {
    id: string;
    login: string;
    display_name: string;
    type: string;
    broadcaster_type: string;
    description: string;
    profile_image_url: string;
    offline_image_url: string;
    view_count: number;
    email: string;
  };
}
  );

export interface PlayerFields {
  id: string;
  username: string;
}

export interface TokensFields {
  access: string;
  refresh: string;
  id: string;
  expiresIn: number;
}

export function fetchSelfUser() {
  return instance.get<SelfUser>(`/auth/me`);
}

export function webLogIn({ username, pwd }: { username: string, pwd: string }) {
  return instance.post<{ player: PlayerFields, tokens: TokensFields }>(
    `/auth/login/web`,
    { username, pwd },
  );
}

export function webSignUp(
  { email, username, pwd }: { email: string, username: string, pwd: string },
) {
  return instance.post<{status: 'ok'}>(
    '/auth/signup',
    { email, username, pwd },
  );
}

export function refreshTokens(refreshToken: string) {
  return instance.post<{ player: PlayerFields, tokens: TokensFields }>(
    'auth/refresh-tokens/web', { refreshToken },
  );
}

export function webConfirmEmail(
  { code, username }: { code: string, username: string },
) {
  return instance.post<{ status: 'ok' }>('auth/signup/confirm', {
    code, username,
  });
}

export function webResetPassword(username: string) {
  return instance.post<{ destination: string }>(
    'auth/password/forgot',
    { username },
  );
}

export function webChangePassword(
  { code, username, pwd }: {
    code: string,
    username: string,
    pwd: string
  }) {
  return instance.post<{ status: 'ok' }>(
    'auth/password/forgot/confirm',
    { code, username, pwd },
  );
}

export function resendConfirmationEmail(username: string) {
  return instance.post<{ destination: string }>(
    'auth/signup/resend',
    { username },
  );
}

export function changePassword({ oldPwd, pwd }: { oldPwd: string, pwd: string }) {
  return instance.post<{ status: 'ok' }>('auth/password/change',
    { oldPwd, pwd });
}

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {}

interface State {
    error?: Error;
}

export default class ErrorBoundary extends React.PureComponent<Props, State> {
    state: State = {};

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error, errorInfo.componentStack);
    }

    static getDerivedStateFromError(error: Error) {
        return {
            error,
        };
    }

    closeErrorPopup = () => {
        this.setState({
            error: undefined,
        });
    };

    render() {
        const { children } = this.props;
        const { error } = this.state;

        return (
            <>
                {!error && children}

                <Modal show={!!error} onHide={this.closeErrorPopup}>
                    <Modal.Header closeButton>
                        <b>Uh oh!</b>
                    </Modal.Header>
                    <Modal.Body>
                        We&apos;re sorry but something went wrong! Try again
                        later?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='primary'
                            onClick={this.closeErrorPopup}
                        >
                            :(
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { Button, Col, Dropdown, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

import FullWidthDropdown from '../../../components/FullWidthDropdown';
import DropdownMenuWithFilter from '../../../components/DropdownMenuWithFilter';
import formatPlayerReward from '../../../utils/format/formatPlayerReward';
import { State } from '../../../utils/hooks/useRequest';
import type { GoldenKeyFormItem, GoldenKeyItem } from './index';
import { PlayerReward } from '../../../api/rewards';

interface BaseProps {
  onClose: () => void;
  rewardsList: State<{ rewards: PlayerReward[] }>;
}

interface EditProps {
  goldenKeyItem: GoldenKeyItem | null;
  onUpdated: (newItem: GoldenKeyFormItem) => void;
  onDeleted: () => void;
  onCreated?: undefined;
  goldenKeys: GoldenKeyItem[];
}

interface AddProps {
  onCreated: (newItem: GoldenKeyFormItem) => void;
  goldenKeyItem?: undefined;
  onUpdated?: undefined;
  onDeleted?: undefined;
  goldenKeys: GoldenKeyItem[] | null;
}

type Props = BaseProps & (EditProps | AddProps);

const formSchema = yup
  .object({
    chance: yup.number().moreThan(0).max(100).required(),
    chanceStr: yup.string().required(),
    variantId: yup.string().required(),
    stock: yup.number().integer().min(0).required(),
    rewardId: yup.string().required(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const EditGoldenKeyItemPopup: React.FC<Props> = ({ rewardsList, onClose, ...props }) => {

  const { goldenKeyItem, onCreated, onUpdated, onDeleted, goldenKeys } = props;
  const [goldenKeyDeleteConfirmation, setGoldenKeyDeleteConfirmation] = useState(false);

  const formInitialValues = useMemo<FormType>(() => {
    if (goldenKeyItem) {
      return {
        chance: goldenKeyItem.chance,
        chanceStr: goldenKeyItem.chance.toString(),
        stock: goldenKeyItem.stockToGive,
        variantId: goldenKeyItem.variantId,
        rewardId: goldenKeyItem.rewardId,
      };
    }
    return {
      chance: 0.000, stock: 0, variantId: '', rewardId: '', chanceStr: '0.000',
    };
  }, [goldenKeyItem]);

  return (
    <Root>
      <Formik<FormType>
        initialValues={formInitialValues}
        validationSchema={formSchema}
        onSubmit={(values) => {
          const playerReward = rewardsList?.data?.rewards
            .find((r) => r.id === values.rewardId);
          const variant = playerReward?.variants
            .find((r) => r.id === values.variantId);
          const newItem = {
            rewardId: playerReward?.id ?? goldenKeyItem?.rewardId ?? '',
            variantId: variant?.id ?? goldenKeyItem?.variantId ?? '',
            stockToGive: values.stock,
            chance: values.chance,
          };
          if (onCreated) {
            onCreated(newItem);
          } else if (goldenKeyItem && onUpdated) {
            onUpdated(newItem);
          }
        }}
      >
        {({
            values,
            setFieldValue,
            setFieldError,
            handleSubmit,
            isValid,
            errors,
          }) => {
          const playerReward = (rewardsList.status === 'FULFILLED')
            ? rewardsList.data.rewards.find(
              (r) => r.id === values.rewardId,
            )
            : undefined;
          const variant = playerReward
            ? playerReward.variants.find(
              (r) => r.id === values.variantId,
            )
            : undefined;
          return (
            <>
              <Modal.Header>
                <b>
                  {goldenKeyItem ? (
                    <>Edit golden key</>
                  ) : (
                    <>New golden key</>
                  )}
                </b>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Form.Row>
                    <Col lg={6}>
                      <Form.Label>Reward</Form.Label>
                      <Form.Group>
                        <FullWidthDropdown>
                          <Dropdown.Toggle variant='light'>
                            {(
                              playerReward ? (
                                formatPlayerReward(playerReward)
                              ) : (
                                <>Select reward...</>
                              )
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            as={DropdownMenuWithFilter}
                            filter={(text: string, i: number) =>
                              rewardsList.data?.rewards[i].title
                                .toLowerCase()
                                .includes(text.toLowerCase()) ?? false
                            }
                          >
                            {rewardsList.data?.rewards.map((r) => (
                              <Dropdown.Item
                                key={r.id}
                                onClick={() => setFieldValue(`rewardId`, r.id)}
                                onSelect={() => {
                                  setFieldValue('variantId', '');
                                  setFieldValue('stock', 0);
                                }}
                              >
                                {formatPlayerReward(r)}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </FullWidthDropdown>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Label>Variant{' '}</Form.Label>
                      <Form.Group>
                        <FullWidthDropdown>
                          <Dropdown.Toggle disabled={!playerReward && !goldenKeyItem} variant='light'>
                            {variant ? (
                              <>{variant.title}</>
                            ) : (
                              <>Select variant...</>
                            )}
                          </Dropdown.Toggle>
                          <span style={{ color: 'red', fontSize: '13px' }}>{errors.variantId}</span>
                          <Dropdown.Menu
                            as={DropdownMenuWithFilter}
                            filter={(text: string, i: number) =>
                              (playerReward ??
                                rewardsList?.data?.rewards.find((el) => el.id === goldenKeyItem?.rewardId))?.variants[i].title
                                .toLowerCase()
                                .includes(text.toLowerCase()) ?? false
                            }
                          >
                            {(playerReward ??
                              rewardsList?.data?.rewards.find((el) => el.id === goldenKeyItem?.rewardId))
                              ?.variants.map((v) => (
                                <Dropdown.Item
                                  key={v.id}
                                  onClick={() => {
                                    const cond = (goldenKeys ?? []).filter((r) => r.rewardId === playerReward?.id).some((el) => v.id === el.variantId);
                                    if (cond && v.id !== goldenKeyItem?.variantId) {
                                      setFieldError(`variantId`, 'Already exists!');
                                      setFieldValue(`variantId`, v.id, false);
                                    } else {
                                      setFieldValue(`variantId`, v.id);
                                    }
                                    setFieldValue('stock', 0);
                                  }}
                                >
                                  {v.title}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </FullWidthDropdown>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col lg={6}>
                      <Form.Label>Stock</Form.Label>
                      <Form.Group>
                        <Form.Control
                          type='number'
                          name='stock'
                          value={values.stock}
                          disabled={!variant && !goldenKeyItem}
                          max={(variant && variant.quantity > 0) ? variant.quantity : 0}
                          min={0}
                          onChange={(e) => {
                            if (parseInt(e.target.value, 10) > ((variant && (variant.quantity > 0)) ? variant.quantity : 0)) {
                              setFieldError('stock', 'Enter valid stock');
                              return;
                            }
                            setFieldValue('stock', parseInt(e.target.value, 10));
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Label>Chance</Form.Label>
                      <Form.Group>
                        <Form.Control
                          name='chanceStr'
                          value={values.chanceStr}
                          onChange={(e) => {
                            setFieldValue('chanceStr', (e.target.value));
                            setFieldValue('chance', parseFloat(e.target.value));
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  onClick={() => handleSubmit()}
                  disabled={!isValid || (!playerReward && !goldenKeyItem) ||
                  (variant?.id !== goldenKeyItem?.variantId &&
                    (goldenKeys ?? []).filter((r) => r.rewardId === playerReward?.id)
                      .some((el) => variant?.id === el.variantId)
                  )}
                >
                  Save
                </Button>
                {onDeleted && goldenKeys?.length && (goldenKeys.length > 1) && (
                  goldenKeyDeleteConfirmation ? (
                    <Button
                      variant='danger'
                      className='mr-auto'
                      onClick={() => {
                        onDeleted();
                        onClose();
                      }}
                    >
                      Press again to confirm deletion
                    </Button>
                  ) : (
                    <Button
                      variant='danger'
                      className='mr-auto'
                      onClick={() => setGoldenKeyDeleteConfirmation(true)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      &nbsp;Delete
                    </Button>
                  )
                )}

              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

export default EditGoldenKeyItemPopup;

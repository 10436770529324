import instance from '../instance';

export async function connectAccount({ code }: { code: string }) {
  return instance.post<{ status: 'ok' }>(`/twitch/connect`, {
    code,
  });
}

export async function disconnectAccount() {
  return instance.post<{ status: 'ok' }>(`/twitch/disconnect`);
}

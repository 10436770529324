import React from 'react';
import styled from 'styled-components';

import BoxBGImage from '../../../assets/images/BoxBGSimplified.png';
import BoxCornerImage from '../../../assets/images/BoxCorner.png';

const USERNAME = 'username';

export function setUsernameToLS(username: string): void {
  localStorage.setItem(USERNAME, username);
}

export function getUsernameFromLS(): string | undefined {
  const usernameStr = localStorage.getItem(USERNAME);
  if (!usernameStr) {
    return undefined;
  }
  try {
    return usernameStr;
  } catch (e) {
    console.error('Unable to parse local storage username:', e);
  }
  return undefined;
}

const Auth: React.FC = ({ children }) => {
  return (
    <FormContainer>
        {children}
    </FormContainer>
  );
};

const FormContainer = styled.div`
  margin: auto;
  background-image: url(${BoxCornerImage}), url(${BoxBGImage});
  background-repeat: no-repeat;
  background-size: auto, cover;
  background-position: top left, center;
  max-width: 488px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  color: #484c5c;
`;

export default Auth;

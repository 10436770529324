import instance from '../instance';
import type { Season } from '../seasons';
import { PlayerReward } from '../rewards';

export interface PlayerTier {
  id: string;
  name: string;
  topPercentage: number;
  rewards: { [seasonId: number]: TierReward[] };
}

export type TierReward = Omit<PlayerReward, 'id'> & {
  id: string;
  rewardId: string;
  discountPercentage: number;
}

export interface PlayerTierUpdate {
  name: PlayerTier['name'];
  topPercentage: PlayerTier['topPercentage'];
  rewards: {
    [seasonId: number]: {
      id: TierReward['id'];
      rewardId: TierReward['rewardId'];
      discountPercentage: TierReward['discountPercentage'];
    }[]
  };
}

export interface PlayerStats {
  id: string;
  username: string;
  elo: number;
  rank: number;
  lastPlayed: number;
  playedCount: number;
  topPercentage: number;
}

export function listPlayerTiers() {
  return instance.get<{ tiers: PlayerTier[] }>('/player-tiers');
}

export function listPlayersInTier({
  tierId,
  seasonId,
  startKey,
}: {
  tierId: PlayerTier['id'];
  seasonId: Season['Season'];
  startKey?: string | undefined;
}) {
  return instance.get<{
    season: Season | null;
    tier: PlayerTier;
    players: PlayerStats[];
    nextStartKey?: string | undefined;
  }>(`/player-tiers/${tierId}/season/${seasonId}/players`, {
    params: {
      startKey
    }
  });
}

export function updatePlayerTier({
  id,
  payload,
}: {
  id: PlayerTier['id'];
  payload: PlayerTierUpdate;
}) {
  return instance.post<{ tier: PlayerTier }>(`/player-tiers/${id}`, payload);
}

export function createPlayerTier(payload: PlayerTierUpdate) {
  return instance.post<{ tier: PlayerTier; tiers: PlayerTier[] }>(
    `/player-tiers`,
    payload,
  );
}

export function deletePlayerTier(id: PlayerTier['id']) {
  return instance.delete<{ status: 'ok' }>(`/player-tiers/${id}`);
}

import React, { useEffect, useMemo } from 'react';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import _memoize from 'lodash/memoize';

import Loader from '../../components/Loader';
import { useSelector } from 'react-redux';
import { selectTokens } from '../../redux/auth/selectors';

type CognitoGroup = 'admins';

interface IdTokenPayload {
    sub: string;
    'cognito:groups'?: CognitoGroup[];
    email_verified: boolean;
    'cognito:username': string;
    exp: number;
    email: string;
}

const AdminGuard: React.FC = ({ children }) => {
    const isAdmin = useIsAdmin();
    const history = useHistory();

    useEffect(() => {
        if (!isAdmin) {
            history.replace('/');
        }
    }, [isAdmin, history]);

    if (isAdmin) {
        return <>{children}</>;
    }

    return <Loader />;
};

export const checkIsAdmin = _memoize((token: string): boolean => {
    const tokenPayload = jwt.decode(token) as IdTokenPayload;
    return tokenPayload['cognito:groups']?.includes('admins') ?? false;
});

export function useIsAdmin() {
    const tokens = useSelector(selectTokens);

    return useMemo(() => {
        if (!tokens.data) {
            return false;
        }

        return checkIsAdmin(tokens.data.id);
    }, [tokens]);
}

export default AdminGuard;

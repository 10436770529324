import instance from '../instance';

export interface DownloadLink {
  id: string;
  url: string;
  isCDNUrl: boolean;
  updatedBy: {
    id: string;
    username: string;
  };
  updatedAt: number;
}

export async function getDownloadLink(type: string) {
  return instance.get<{ url: string }>(`/download-links/${type}`);
}

export async function saveDownloadLink({ type, url, isCDNUrl }: {
  type: string,
  url: string,
  isCDNUrl: boolean
}) {
  return instance.post<{ downloadLinks: DownloadLink[] }>(`/download-links/${type}`,
    { url, isCDNUrl });
}

export async function listDownloadLinks() {
  return instance.get<{ downloadLinks: DownloadLink[], types: string[] }>(`/download-links`);
}

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import LogoImage from '../../../assets/images/LOGO.png';
import FooterLogoImage from '../../../assets/images/FooterLogo.png';
import BGImage from '../../../assets/images/BG.jpg';
import { Breakpoints } from '../../utils/responsive';
import PdfPrivacyPolicy from '../../../assets/documents/Final_Mouse_-_Privacy_PolicyLATEST.pdf';
import PdfTermsOfUse from '../../../assets/documents/Aim_Gods_Terms_of_Use_forwebsite.pdf';

const Layout: React.FC = ({ children }) => {
  return (
    <Root>
      <Content>
        <LogoLink to='/'>
          <LogoWrapper ImgSrc={LogoImage} />
        </LogoLink>
        {children}
      </Content>
      <Footer>
        <img src={FooterLogoImage} alt='logo' />
        <Links>
          <a href={PdfPrivacyPolicy} target='_blank' rel="noreferrer">
            Privacy Policy
          </a>
          &nbsp;- &nbsp;
          <a href={PdfTermsOfUse} target='_blank' rel="noreferrer">
            Terms of Use
          </a>
        </Links>
        <p>Finalmouse LLC. ALL RIGHTS RESERVED.</p>
      </Footer>
    </Root>
  );
};

const LogoLink = styled(Link)`
  &:focus {
    outline: none;
  }
`;

const LogoWrapper = styled.div<{ ImgSrc: string }>`
  height: 170px;
  overflow: hidden;
  background-image: ${(props) => `url(${props.ImgSrc})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;

  &:focus {
    outline: none;
  }

  @media (max-width: ${Breakpoints.md}px) {
    background-size: cover;
  }
`;

const Links = styled.div`
  color: #515463;
  margin: auto;
  display: flex;
  justify-content: center;
  text-align: center;

  a {
    color: #515463;
    text-transform: uppercase;

    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  @media (max-width: 430px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Footer = styled.footer`
  background-color: rgb(25 28 38);
  min-height: max-content;
  position: relative;

  img {
    display: block;
    margin: 36px auto;
  }

  p {
    color: #333749;
    text-align: center;
    margin: 30px;
  }
`;

const Content = styled.div`
  width: 60%;
  margin: auto;
  position: relative;

  @media (max-width: 1300px) {
    width: 70%;
  }

  @media (max-width: 1150px) {
    width: 75%;
  }

  @media (max-width: 1050px) {
    width: 80%;
  }

  @media (max-width: 992px) {
    width: 85%;
  }

  @media (max-width: ${Breakpoints.md}px) {
    margin: 0 auto 50px auto;
  }

  @media (max-width: 810px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 95%;
  }

  @media (max-width: 440px) {
    width: 100%;
  }
`;

const Root = styled.div`
  display: flex;
  justify-content: center;
  background-image: url(${BGImage});
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  background-position-x: center;
  width: 100%;
`;

export default Layout;

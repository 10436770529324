import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import styled from 'styled-components';

import { DownloadLink } from '../../../api/download-links';

interface BaseProps {
  onClose: () => void;
  downloadLinks: Omit<DownloadLink, 'updatedAt' | 'updatedBy'>[];
  isLoading: boolean;
  isError: boolean;
  error?: string;
  types: string[];
}

interface EditProps {
  downloadLinkItem: Omit<DownloadLink, 'updatedAt' | 'updatedBy'>;
  onUpdated: (newItem: Omit<DownloadLink, 'updatedAt' | 'updatedBy'>) => void;
  onCreated?: undefined;
}

interface AddProps {
  downloadLinkItem?: undefined;
  onCreated: (newItem: Omit<DownloadLink, 'updatedAt' | 'updatedBy'>) => void;
  onUpdated?: undefined;
}

type Props = BaseProps & (EditProps | AddProps);

const formSchema = yup
  .object({
    type: yup.string().required(),
    url: yup.string().required(),
    isCDNUrl: yup.boolean().required(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const EditDownloadLinkPopup: React.FC<Props> = ({
                                                  onClose,
                                                  downloadLinks,
                                                  isLoading,
                                                  isError,
                                                  error,
                                                  types,
                                                  ...props
                                                }) => {
  const { downloadLinkItem, onCreated, onUpdated } = props;
  const [isCDN, setIsCDN] = useState(downloadLinkItem?.isCDNUrl ?? false);

  const getRemainingTypes = useCallback(() =>
      types.filter((type) =>
        !(downloadLinks.map((el) => el.id).includes(type))),
    [downloadLinks, types]);

  const formInitialValues = useMemo<FormType>(() => {
    if (downloadLinkItem) {
      return {
        type: downloadLinkItem.id,
        url: downloadLinkItem.url,
        isCDNUrl: downloadLinkItem.isCDNUrl,
      };
    }
    return {
      type: '', url: '', isCDNUrl: false,
    };
  }, [downloadLinkItem]);
  return (
    <>
      <Formik<FormType>
        initialValues={formInitialValues}
        validationSchema={formSchema}
        onSubmit={(values) => {
          const newItem = {
            id: values.type,
            url: values.url,
            isCDNUrl: isCDN,
          };
          if (downloadLinkItem && onUpdated) {
            onUpdated(newItem);
          } else if (onCreated) {
            onCreated(newItem);
          }

        }}
      >
        {({
            values,
            setFieldValue,
            handleSubmit,
            isValid,
          }) => {
          return (
            <>
              <Modal.Header>
                <b>
                  {downloadLinkItem ? (
                    <>Edit download link</>
                  ) : (
                    <>New download link</>
                  )}
                </b>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(e) =>
                  e.preventDefault()}>
                  <Form.Row>
                    <Col lg={3}>
                      <Form.Label>Type</Form.Label>
                      <Dropdown>
                        <CustomDropdown
                          id="type"
                          disabled={!getRemainingTypes().length || downloadLinkItem}>
                          {(
                            values.type ? (values.type) : (
                              <>{!getRemainingTypes().length ?
                                'No types left' : 'Select type...'}</>
                            )
                          )}
                        </CustomDropdown>
                        <Dropdown.Menu>
                          {getRemainingTypes().map((el, index) =>
                            (
                              <Dropdown.Item
                                key={el} eventKey={(index + 1).toString()}
                                onClick={() => setFieldValue('type', el)}
                              >{el}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col lg={8}>
                      <Form.Label>Url</Form.Label>
                      <Form.Control
                        value={values.url}
                        onChange={(e) =>
                          setFieldValue('url', e.target.value)} />
                    </Col>
                    <Col lg={1}>
                      <Form.Label htmlFor='isCDN'>Is CDN Url</Form.Label>
                      <Form.Check type='checkbox'>
                        <Form.Check.Input
                          id='isCDN' type='checkbox' checked={isCDN}
                          style={{ width: 70, height: 30, cursor: 'pointer' }}
                          onChange={() => setIsCDN(!isCDN)} />
                      </Form.Check>
                    </Col>
                  </Form.Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                {isError ? (
                  <Col style={{ color: 'red' }}>{error}</Col>
                ) : ''}
                <Button variant='secondary' onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant='primary' onClick={() => handleSubmit()}
                  disabled={!isValid || isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />
                      Loading...
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Modal.Footer>
            </>
          );
        }
        }
      </Formik>
    </>
  );
};

const CustomDropdown = styled(Dropdown.Toggle)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: black;
  border: 1px solid #ced4da;

  &:hover, &:focus, &:active, &:disabled {
    background-color: white !important;
    color: black !important;
    border: 1px solid #ced4da !important;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export default EditDownloadLinkPopup;

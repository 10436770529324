import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useAPI } from '../../utils/hooks/useRequest';
import api from '../../api';
import AuthService from '../../utils/auth/AuthService';
import { setUsernameToLS } from './index';
import { Breakpoints } from '../../utils/responsive';

const formSchema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const LogInForm = () => {
  const formInitialValues = useMemo<FormType>(() => (
    { username: '', password: '' }
  ), []);

  const history = useHistory();

  const { state: logInState, fetch: logInFetch } = useAPI(
    api.auth.webLogIn,
  );

  const handleSignIn = (username: string, pwd: string) => {
    setUsernameToLS(username);
    logInFetch({ pwd, username });
  };

  useEffect(() => {
    if (logInState.status === 'FULFILLED') {
      const { id, access, expiresIn, refresh } = logInState?.data?.tokens;
      const curTokens = { id, access, refresh, expiresIn: expiresIn.toString() };
      AuthService.setTokens(curTokens);
      history.replace('/');
    }
  }, [history, logInState.status, logInState?.data?.tokens]);

  useEffect(() => {
    if (logInState.status === 'REJECTED' &&
      logInState.error?.message === 'Email for this account is not confirmed') {
      history.replace('/auth/confirm-email');
    }
  }, [logInState.status, logInState?.error, history]);

  return (
    <Container>
      <Formik<FormType>
        validationSchema={formSchema}
        initialValues={formInitialValues}
        onSubmit={({ username, password }) => {
          handleSignIn(username, password);
        }}
      >
        {({
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
          <>
            <Input id="username" type="text" placeholder="Username"
                   isInvalid={!!errors.username && !!touched.username}
                   onChange={event =>
                     setFieldValue('username', event.target.value)} />
            {errors.username && touched.username && (
              <FieldError>* {errors.username}</FieldError>
            )}
            <Input id="password" type="password" placeholder="Password"
                   isInvalid={!!errors.password && !!touched.password}
                   onChange={event =>
                     setFieldValue('password', event.target.value)} />
            {errors.password && touched.password && (
              <FieldError>* {errors.password}</FieldError>
            )}
            <LoginButton
              onClick={(e) => {
                e.preventDefault();
                return submitForm();
              }}
              type="submit"
              disabled={logInState.status === 'IN_PROGRESS'}>
              {logInState.status === 'IN_PROGRESS' ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    color='white'
                    style={{ marginRight: 10, marginBottom: 5 }}
                  />
                  Loading...
                </>
              ) : (
                'Log In'
              )}
            </LoginButton>
          </>
        )}
      </Formik>
      {(logInState.status === 'REJECTED') ? (
        (logInState.error.message === 'Too many requests') ? (
          <Error>
            We’re experiencing a ton of requests right now.
            Please try again in a moment.
          </Error>
        ) : (
          <Error>{logInState.error.message}</Error>
        )
      ) : ''}
      <OrLabel />
      <LoginLink to='/auth/sign-up'>
        Create Free Account
      </LoginLink>
      <LoginLink to='/auth/reset-password'>
        Can&apos;t log in?
      </LoginLink>
    </Container>
  );
};

const Error = styled.div`
  color: red;
  margin-top: 5px;
  font-size: 14px;
`;

const FieldError = styled(Error)`
  margin-top: -10px;
  margin-bottom: 5px;
`;

const Container = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  width: 100%;
`;

const LoginButton = styled.button`
  border: none;
  background-image: linear-gradient(to left, #62451f, #b17e33);
  color: #ffffff;
  padding: 2px;
  font-size: 24px;
  font-weight: 300;
  margin-top: 5px;
  cursor: pointer;

  &:hover, &:focus {
    background-image: linear-gradient(to left, #b17e33, #d2a75d);
    outline: none;
  }
`;

const Input = styled.input<{ isInvalid: boolean }>`
  border: none;
  padding: 3px 10px;
  outline: ${({ isInvalid }) => isInvalid ? '1px solid red' : 'none'};
  background-color: #151515;
  color: white;
  margin-bottom: 14px;

  &::placeholder {
    color: white;
  }

  &:active, &:focus {
    outline: 1px solid white;
    background: #222222;
    color: white;
    border: none;
    font-size: 16px;
  }
`;

const OrLabel = styled.div`
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;

  &::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 25%;
    border-bottom: 1px solid #484c5c;
    width: 50%;
  }

  &::after {
    content: "OR";
    position: absolute;
    top: -12px;
    left: 45%;
    padding: 0 10px;
    background: #222222;

    @media (max-width: ${Breakpoints.iphone5}px) {
      left: 41%;
    }
  }
`;

const LoginLink = styled(Link)`
  color: white;

  &:first-of-type {
    margin-bottom: 10px;
  }

  &:hover, &:focus {
    color: white;
    text-decoration: underline;
    outline: none;
  }
`;

export default LogInForm;

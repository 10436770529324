import {
    createActionCreators,
    createReducerFunction,
    ImmerReducer,
} from 'immer-reducer';

import { FetchedData } from '../types';
import type { SelfUser } from '../../api/auth';
import * as thunkActions from './actions';
import type { CognitoTokens } from '../../utils/auth/AuthService';

interface State {
    user: FetchedData<SelfUser>;
    tokens:
        | {
              fulfilled: true;
              data?: CognitoTokens;
          }
        | {
              fulfilled: false;
              data?: undefined;
          };
}

const initialState: State = {
    user: {
        fetching: false,
        fetched: false,
    },
    tokens: {
        fulfilled: false,
    },
};

export class AuthReducer extends ImmerReducer<State> {
    setUserPending() {
        this.draftState.user = {
            ...this.draftState.user,
            fetching: true,
            fetched: false,
        };
    }

    setUserFulfilled(user?: SelfUser) {
        this.draftState.user = {
            ...this.draftState.user,
            fetching: false,
            fetched: true,
            data: user,
        };
    }

    setUserRejected(e: Error) {
        this.draftState.user = {
            ...this.draftState.user,
            fetching: false,
            fetched: false,
            error: e,
        };
    }

    setTokens(tokens?: CognitoTokens | undefined) {
        this.draftState.tokens = {
            fulfilled: true,
            data: tokens,
        };
    }

    reset() {
        this.draftState = initialState
    }
}

export const reducer = createReducerFunction(AuthReducer, initialState);
export const actions = {
    ...createActionCreators(AuthReducer),
    ...thunkActions,
};

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { getUsernameFromLS } from './index';
import { Breakpoints } from '../../utils/responsive';
import { useAPI } from '../../utils/hooks/useRequest';
import api from '../../api';

const ConfirmEmailForm = () => {
  const history = useHistory();

  const { state: resendEmailStatus, fetch: resendEmailFetch } = useAPI(
    api.auth.resendConfirmationEmail,
  );

  const handleEmailResend = () => {
    if(resendEmailStatus.status === 'IN_PROGRESS') return;
    if (getUsernameFromLS()) {
      resendEmailFetch(getUsernameFromLS() ?? '');
    }
  };

  useEffect(() => {
    if (!getUsernameFromLS()) {
      history.replace('/auth/log-in');
    }
  }, [history]);

  return (
    <Container>
      <Message>Check your email for the confirmation link!</Message>
      <OrLabel />
      <Message>Didn’t receive a confirmation link?</Message>
      <LoginLink onClick={handleEmailResend}
                 isDisabled={resendEmailStatus.status === 'IN_PROGRESS'}>
        {resendEmailStatus.status === 'IN_PROGRESS' ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              color='white'
              style={{ marginRight: 10 }}
            />
            Loading...
          </>
        ) : (
          'Click here to resend email'
        )}
      </LoginLink>
      {(resendEmailStatus.status === 'REJECTED') ? (
        (resendEmailStatus.error.message === 'Too many requests') ? (
          <Error>
            We’re experiencing a ton of requests right now.
            Please try again in a moment.
          </Error>
        ) : (
          <Error>{resendEmailStatus.error.message}</Error>
        )
      ) : ''}
      {(resendEmailStatus.status === 'FULFILLED') ? (
        <SuccessMsg>
          Confirmation link was resend to {resendEmailStatus.data.destination}
        </SuccessMsg>
      ) : ''}
    </Container>
  );
};

const Error = styled.div`
  color: red;
  margin-top: 5px;
  font-size: 14px;
`;

const SuccessMsg = styled(Error)`
  color: #49b653;
`;

const Message = styled.div`
  text-align: center;
  color: white;
  margin-bottom: 10px;

  &:first-of-type {
    margin-bottom: 0;
    font-size: 20px;
  }
`;

const Container = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  width: 100%;
`;

const LoginLink = styled.div<{isDisabled: boolean}>`
  color: white;
  cursor: ${({isDisabled}) => isDisabled ? 'not-allowed' : 'pointer'};
  text-decoration: ${({isDisabled}) => isDisabled ? 'none' : 'underline'};
`;

const OrLabel = styled.div`
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  &::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 25%;
    border-bottom: 1px solid #484c5c;
    width: 50%;
  }

  &::after {
    content: "OR";
    position: absolute;
    top: -12px;
    left: 45%;
    padding: 0 10px;
    background: #282723;

    @media (max-width: ${Breakpoints.iphone5}px) {
      left: 41%;
    }
  }
`;

export default ConfirmEmailForm;

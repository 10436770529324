import React, { useCallback, useEffect } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../api';
import { useAPI } from '../../utils/hooks/useRequest';
import { useChanged } from '../../utils/hooks/useChanged';
import { actions as authActions } from '../../redux/auth/AuthReducer';
import { selectUser } from '../../redux/auth/selectors';
import Loader from '../../components/Loader';
import { Button, Modal } from 'react-bootstrap';

const TwitchCallback: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const userFetchedChanged = useChanged(user.fetched);

  const {
    state: connectAccountState,
    fetch: connectAccount,
    updateState: updateConnectAccountState,
  } = useAPI(api.twitch.connectAccount);
  const connectAccountStatusChanged = useChanged(connectAccountState.status);
  const connectAccountErrorChanged = useChanged(connectAccountState.error);

  const closeErrorPopup = useCallback(() => {
    updateConnectAccountState((draftState) => {
      draftState.error = undefined;
    });
  }, [updateConnectAccountState]);

  useEffect(() => {
    const params = qs.parse(window.location.search.slice(1));
    if (typeof params.code === 'string') {
      connectAccount({
        code: params.code,
      });
    }
  }, [connectAccount]);

  useEffect(() => {
    if (
      connectAccountStatusChanged &&
      connectAccountState.status === 'FULFILLED'
    ) {
      dispatch(authActions.fetchSelfUser());
    }
  }, [connectAccountStatusChanged, connectAccountState, dispatch]);

  useEffect(() => {
    if (
      connectAccountState.status === 'FULFILLED' &&
      userFetchedChanged &&
      user.fetched
    ) {
      history.replace('/');
    }
  }, [connectAccountState, userFetchedChanged, user, history]);

  useEffect(() => {
    if (connectAccountErrorChanged && !connectAccountState.error) {
      history.replace('/');
    }
  }, [connectAccountErrorChanged, connectAccountState, history]);

  return (
    <>
      <Loader />
      <Modal show={!!connectAccountState.error}>
        {!!connectAccountState.error && (
          <>
            <Modal.Header>Uh oh!</Modal.Header>
            <Modal.Body>
              Unable to connect Twitch account:{' '}
              <b>{connectAccountState.error.message}</b>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={closeErrorPopup}>:(</Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default TwitchCallback;

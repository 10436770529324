import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export default styled(Dropdown)`
  .dropdown-toggle {
    width: 100%;
    text-align: left;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-menu {
    width: 100%;
  }
`;

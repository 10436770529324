import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import urlJoin from 'url-join';
import { Button, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';

import EditGoldenKeyItemPopup from './EditGoldenKeyItemPopup';
import DeleteGoldenKeyItemPopup from './DeleteGoldenKeyItemPopup';
import { useAPI } from '../../../utils/hooks/useRequest';
import api from '../../../api';
import Loader from '../../../components/Loader';
import { Reward } from '../../../api/golden-keys';
import RewardsTable from './RewardsTable';
import WinLoseChances from './WinLoseChances';
import BonusKeys from './BonusKeys';

export interface GoldenKeyItem {
  rewardId_variantId: string;
  rewardId: string;
  variantId: string;
  stockToGive: number;
  chance: number;
  rewardTitle: string;
  rewardImage: string;
  variantTitle: string;
}

export type GoldenKeyFormItem = Pick<Reward,
  'rewardId'
  | 'variantId'
  | 'stockToGive'
  | 'chance'>;

export type TestRollsResponse = {
  rewards: (Pick<Reward,
    'rewardId'
    | 'variantId'
    | 'rewardTitle'
    | 'rewardImage'
    | 'variantTitle'>
    & { count: number; }
    )[]; rollsCount: number;
}

const GoldenKeys: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [goldenKeys, setGoldenKeys] = useState<GoldenKeyItem[] | null>(null);
  const [goldenKeyId, setGoldenKeyId] = useState<string | null>(null);

  const goldenKeyItem = useMemo<GoldenKeyItem | null>(() => {
    if (!goldenKeys) return null;
    return goldenKeys.find((r) =>
      r.rewardId_variantId === goldenKeyId) ?? null;
  }, [goldenKeyId, goldenKeys]);

  const closeEditGoldenKeyPopup = useCallback(() => {
    history.push(match.url);
    setGoldenKeyId(null);
  }, [history, match]);

  const { state: rewardsList, fetch: listRewards } = useAPI(
    api.rewards.listRewards,
  );

  const { state: chances, fetch: getChances } = useAPI<{
    winningChance: number, lossChance: number
  }, undefined>(
    api.goldenKeys.getChances,
  );

  const { state: rewardsToRoll, fetch: getRewardsToRoll } = useAPI<{
    rewards: Reward[], minMaxRollValue: number, maxRollValue: number
  },
    undefined>(
    api.goldenKeys.getRewards,
  );

  const getMinMaxRollValue = (items: GoldenKeyItem[] | null) => items?.length ?
    Math.ceil(100 / (Math.min(...items.map((el) =>
      el.chance)))) : 0;

  const [minMaxRollValue, setMinMaxRollValue] = useState<number>(
    getMinMaxRollValue(goldenKeys),
  );
  const [maxRollValue, setMaxRollValue] = useState<number>(
    getMinMaxRollValue(goldenKeys),
  );

  const [testRolls, setTestRolls] = useState<TestRollsResponse>();

  useEffect(() => {
    setMinMaxRollValue(getMinMaxRollValue(goldenKeys));
    if (maxRollValue < getMinMaxRollValue(goldenKeys)) {
      setMaxRollValue(getMinMaxRollValue(goldenKeys));
    }
  }, [goldenKeys, maxRollValue]);

  const onAdd = useCallback((newItem: GoldenKeyFormItem) => {
    const reward = rewardsList?.data?.rewards.find((r) => r.id === newItem.rewardId);
    const variant = reward?.variants.find((v) => v.id === newItem.variantId);
    const newGoldenKeyItems =
      [...goldenKeys ?? [], {
        rewardId_variantId: `${newItem.rewardId}_${newItem.variantId}`,
        chance: newItem.chance,
        rewardId: newItem.rewardId,
        variantId: newItem.variantId,
        stockToGive: newItem.stockToGive,
        rewardTitle: reward?.title ?? '',
        rewardImage: reward?.image ?? '',
        variantTitle: variant?.title ?? '',
        totalStock: variant?.quantity ?? 0,
      }];
    setGoldenKeys(newGoldenKeyItems);
    setMinMaxRollValue(getMinMaxRollValue(newGoldenKeyItems));
    closeEditGoldenKeyPopup();
  }, [goldenKeys, rewardsList?.data?.rewards, closeEditGoldenKeyPopup]);

  const onUpdate = useCallback((newItem: GoldenKeyFormItem) => {
    const updatedGoldenKeys = (goldenKeys ?? []).map((k: GoldenKeyItem) => {
      if (k.rewardId_variantId === goldenKeyId) {
        const reward = rewardsList?.data?.rewards.find((r) => r.id === newItem.rewardId);
        const variant = reward?.variants.find((v) => v.id === newItem.variantId);
        return {
          rewardId_variantId: `${newItem.rewardId}_${newItem.variantId}`,
          chance: newItem.chance,
          rewardId: newItem.rewardId,
          variantId: newItem.variantId,
          stockToGive: newItem.stockToGive,
          rewardTitle: reward?.title ?? '',
          rewardImage: reward?.image ?? '',
          variantTitle: variant?.title ?? '',
          totalStock: variant?.quantity ?? 0,
        };
      }
      return k;
    });
    setGoldenKeys(updatedGoldenKeys);
    setMinMaxRollValue(getMinMaxRollValue(updatedGoldenKeys));
    closeEditGoldenKeyPopup();
  }, [goldenKeyId, goldenKeys, rewardsList?.data?.rewards, closeEditGoldenKeyPopup]);

  const onDelete = useCallback(() => {
    const updatedArray: GoldenKeyItem[] = [];
    (goldenKeys ?? []).forEach((item) => {
      if (item.rewardId_variantId !== goldenKeyId) {
        updatedArray.push(item);
      }
    });
    setGoldenKeys(updatedArray);
    setMinMaxRollValue(getMinMaxRollValue(updatedArray));
    closeEditGoldenKeyPopup();
  }, [goldenKeys, goldenKeyId, closeEditGoldenKeyPopup]);


  const { state: updateRewardsToRollState, fetch: updateRewardsToRoll } = useAPI<{},
    { rewards: GoldenKeyFormItem[], maxRollValue: number }>(
    api.goldenKeys.updateRewards,
  );

  const { state: runTestRollsState, fetch: runTestRollsFetch } = useAPI<TestRollsResponse,
    {
      rewards: Omit<Reward, 'rewardId_variantId' | 'totalStock' | 'price'>[],
      maxRollValue: number
    }>(
    api.goldenKeys.runTestRolls,
  );


  const runTestRolls = useCallback(() => {
    if (!goldenKeys) return;
    const rewards = goldenKeys
      .map(({
              variantId,
              rewardId,
              rewardTitle,
              chance, rewardImage,
              variantTitle,
              stockToGive,
            }) => ({
        variantId, rewardId, rewardTitle, chance, rewardImage, variantTitle, stockToGive,
      }));
    runTestRollsFetch({
      rewards,
      maxRollValue,
    });
  }, [goldenKeys, maxRollValue, runTestRollsFetch]);

  const submitRewards = useCallback(() => {
    if (!testRolls) return;
    const rewards = (goldenKeys ?? [])
      .map(({ rewardId, variantId, stockToGive, chance }) => ({
        rewardId, variantId, stockToGive, chance,
      }));
    updateRewardsToRoll({ rewards, maxRollValue });
  }, [updateRewardsToRoll, goldenKeys, maxRollValue, testRolls]);



  useEffect(() => {
    listRewards(undefined);
  }, [listRewards]);

  useEffect(() => {
    getChances(undefined);
  }, [getChances]);

  useEffect(() => {
    getRewardsToRoll(undefined);
  }, [getRewardsToRoll]);

  useEffect(() => {
    if (rewardsToRoll.status === 'FULFILLED') {
      setGoldenKeys(rewardsToRoll?.data?.rewards);
    }
  }, [rewardsToRoll]);

  useEffect(() => {
    if (runTestRollsState.status === 'FULFILLED') {
      setTestRolls(runTestRollsState?.data);
    }
  }, [runTestRollsState]);


  if (rewardsList.status !== 'FULFILLED' ||
    chances.status !== 'FULFILLED' ||
    rewardsToRoll.status !== 'FULFILLED') {
    return <Loader />;
  }

  return (
    <>
      <RewardsTable goldenKeys={goldenKeys} setGoldenKeyId={setGoldenKeyId} />
      <Container>
        <Row className='mt-5'>
          <Col>
            <Button variant='success' onClick={runTestRolls}
                    disabled={runTestRollsState.status === 'IN_PROGRESS'}>
              {runTestRollsState.status === 'IN_PROGRESS' ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2"
                  />
                  Loading...
                </>
              ) : (
                'Roll Simulation'
              )}
            </Button>
          </Col>
          <Col>
            <Button onClick={submitRewards} variant='success'
                    disabled={!testRolls || updateRewardsToRollState.status === 'IN_PROGRESS'}
            > {updateRewardsToRollState.status === 'IN_PROGRESS' ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
                Loading...
              </>
            ) : (
              'Save'
            )}</Button>
          </Col>
        </Row>
        {(runTestRollsState.status === 'REJECTED') ? (
          <div style={{ color: 'red' }}>
            Roll simulation error: {runTestRollsState.error.message}
          </div>
        ) : ''}
        {(updateRewardsToRollState.status === 'REJECTED') ? (
          <div style={{ color: 'red' }}>
            Save rewards error: {updateRewardsToRollState.error.message}
          </div>
        ) : ''}
        {testRolls && runTestRollsState.status === 'FULFILLED' && (
          <>
            {testRolls.rewards.length ? (
              <Table striped bordered hover className='mt-2' variant="dark">
                <thead>
                <tr>
                  <th>Reward</th>
                  <th>Variant</th>
                  <th>Count</th>
                </tr>
                </thead>
                <tbody>
                {testRolls.rewards.map((reward) => (
                  <tr key={`${reward.rewardId}_${reward.variantId}`}>
                    <td>{reward.rewardTitle}</td>
                    <td>{reward.variantTitle}</td>
                    <td>{reward.count}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
            ) : (
              <p style={{ color: 'white' }}>Nothing was won..</p>
            )}
            <p style={{ color: 'white' }}>Rolls Count - {testRolls.rollsCount}</p>
          </>
        )}
        <Row className='mt-5 mb-5'>
          <Col md={3}>
            <Form.Group>
              <Form.Label style={{ color: 'white' }}>Minimum Max Roll Value</Form.Label>
              <Form.Control
                name='minMaxRollValue'
                value={minMaxRollValue}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={{ span: 3, offset: 1 }}>
            <Form.Group>
              <Form.Label style={{ color: 'white' }}>Max Roll Value</Form.Label>
              <Form.Control
                type='number'
                name='maxRollValue'
                value={maxRollValue}
                onChange={(e) =>
                  setMaxRollValue(parseFloat(e.target.value))}
              />
            </Form.Group>
          </Col>
        </Row>
        <WinLoseChances chances={chances.data} />
        <BonusKeys/>
      </Container>
      <>
        <Route exact path={urlJoin(match.path, '/:goldenKeyId/edit')}>
          {({ match: curMatch }) => (goldenKeys !== null) && (
            <Modal
              show={!!curMatch}
              onHide={closeEditGoldenKeyPopup}
              keyboard
              size='xl'
            >
              <EditGoldenKeyItemPopup
                rewardsList={rewardsList}
                onClose={closeEditGoldenKeyPopup}
                onUpdated={onUpdate}
                onDeleted={onDelete}
                goldenKeyItem={goldenKeyItem}
                goldenKeys={goldenKeys}
              />
            </Modal>
          )}
        </Route>

        <Route exact path={urlJoin(match.path, '/new')}>
          {({ match: curMatch }) => (
            <Modal
              show={!!curMatch}
              onHide={closeEditGoldenKeyPopup}
              keyboard
              size='xl'
            >
              <EditGoldenKeyItemPopup
                onCreated={onAdd}
                rewardsList={rewardsList}
                onClose={closeEditGoldenKeyPopup}
                goldenKeys={goldenKeys} />
            </Modal>
          )}
        </Route>

        <Route exact path={urlJoin(match.path, '/:goldenKeyId/delete')}>
          {({ match: curMatch }) => (goldenKeyItem !== null) && (
            <Modal
              show={!!curMatch}
              onHide={closeEditGoldenKeyPopup}
              keyboard
              size='xl'
            >
              <DeleteGoldenKeyItemPopup
                onClose={closeEditGoldenKeyPopup}
                onDelete={onDelete}
                goldenKeyItem={goldenKeyItem} />
            </Modal>
          )}
        </Route>
      </>
    </>
  );
};

export default GoldenKeys;

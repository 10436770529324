import React, { useMemo } from 'react';
import { Button, Card, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useRouteMatch } from 'react-router-dom';
import urlJoin from 'url-join';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { GoldenKeyItem } from './index';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import styled from 'styled-components';

interface Props {
  goldenKeys: GoldenKeyItem[] | null;
  setGoldenKeyId:
    (value: (((prevState: (string | null)) =>
      (string | null)) | string | null)) => void;
}

const RewardsTable: React.FC<Props> = ({ goldenKeys, setGoldenKeyId }: Props) => {
  const match = useRouteMatch();
  const addNewItemButton = useMemo(
    () => (
      <Link to={urlJoin(match.url, '/new')}>
        <Button variant='success' className='mt-2'>
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;Add new item
        </Button>
      </Link>
    ),
    [match],
  );

  return (
    <Container>
      {addNewItemButton}
      <Card className='mt-2'>
        <DataTable<GoldenKeyItem>
          title='Golden Key Rewards'
          responsive
          striped
          highlightOnHover
          theme='dark'
          columns={[
            {
              name: 'Title',
              selector: ({ rewardTitle }) => rewardTitle,
              sortable: true,
            },
            {
              name: 'Image',
              selector: ({ rewardImage }) => rewardImage,
              format: ({ rewardImage }) => (
                rewardImage ?
                  <img src={rewardImage} alt='reward' width='40px' height='40px' />
                  : ''),
              sortable: true,
            },
            {
              name: 'Variant Id',
              selector: ({ variantTitle }) => (variantTitle),
              sortable: true,
            },
            {
              name: 'Stock To Give Away',
              selector: ({ stockToGive }) => stockToGive,
              sortable: true,
            },
            {
              name: '% Chance To Win',
              selector: ({ chance }) => chance,
              sortable: true,
            },
            {
              name: 'Winning Possibilities',
              selector: ({ chance }) =>
                Math.round(chance / (Math.min(...(goldenKeys ?? [])
                  .map((el) => el.chance), chance))),
              sortable: true,
            },
            {
              name: '',
              right: true,
              cell: (item) => (
                <>
                  <Link
                    to={urlJoin(match.url,
                      `/${encodeURIComponent(item.rewardId_variantId)}/edit`)}
                    title='Edit'
                  >
                    <TableButton
                      variant='primary'
                      onClick={() => setGoldenKeyId(item.rewardId_variantId)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </TableButton>
                  </Link>
                  {(goldenKeys && goldenKeys.length > 1) ? (
                    <Link
                      to={urlJoin(match.url,
                        `/${encodeURIComponent(item.rewardId_variantId)}/delete`)}
                      className='ml-2'
                      title='Delete'
                    >
                      <TableButton
                        variant='danger'
                        onClick={() => setGoldenKeyId(item.rewardId_variantId)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </TableButton>
                    </Link>
                  ) : (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      trigger={['click', 'hover', 'focus']}
                      overlay={<Tooltip id="tooltip">
                        You cannot delete last item!
                      </Tooltip>}
                    >
                      <TableButton
                        className='ml-2'
                        variant='danger'
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </TableButton>
                    </OverlayTrigger>
                  )}
                </>
              ),
            },
          ]}
          data={goldenKeys ?? []}
        />
      </Card>
      {addNewItemButton}
    </Container>
  );
};

const TableButton = styled(Button)`
  white-space: nowrap;
`;

export default RewardsTable;

import React from 'react';
import parse from 'html-react-parser';

import renderLoader from '../../../assets/loader.ejs';

interface Props {
    size?: 'small' | 'normal';
}

const Loader: React.FC<Props> = ({ size = 'normal' }) => {
    return (
        <React.Fragment key='loader'>
            {parse(
                renderLoader({
                    size,
                }),
            )}
        </React.Fragment>
    );
};

export default Loader;

import instance from '../instance';

export async function redeemGameKey(gameKey: string) {
  return instance.post<{ status: 'ok' }>('/gamekeys/redeem', {
    gameKey,
  });
}

export async function resendGameKeys() {
  return instance.post<{status: 'ok'}>('/gamekeys/resend');
}

import instance from '../instance';
import type { PlayerTier } from '../player-tiers';
import { PlayerFields } from '../auth';

export type NewsfeedItemPriority = 'LOW' | 'MEDIUM' | 'HIGH';

export interface NewsfeedItem {
  id: string;
  subject: string;
  body: string;
  startDate: number;
  endDate?: number;
  priority: NewsfeedItemPriority;
  playerTier?: PlayerTier;
  createdBy?: PlayerFields;
  createdAt: number;
  updatedBy?: PlayerFields;
  updatedAt: number;
}

export async function listNewsfeedItems() {
  return instance.get<{ newsfeed: NewsfeedItem[] }>('/newsfeed');
}

export async function createNewsfeedItem(
  data: Pick<
    NewsfeedItem,
    'subject' | 'body' | 'startDate' | 'endDate' | 'priority'
  > & {
    playerTierId?: string;
  },
) {
  return instance.post<{ newsfeed: NewsfeedItem[] }>('/newsfeed', data);
}

export async function updateNewsfeedItem({
  id,
  data,
}: {
  id: NewsfeedItem['id'];
  data: Pick<
    NewsfeedItem,
    'subject' | 'body' | 'startDate' | 'endDate' | 'priority'
  > & {
    playerTierId?: string;
  };
}) {
  return instance.post<{ newsfeedItem: NewsfeedItem }>(`/newsfeed/${id}`, data);
}

export async function deleteNewsfeedItem(id: NewsfeedItem['id']) {
  return instance.delete<{ status: 'ok' }>(`/newsfeed/${id}`);
}

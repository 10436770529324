import instance from '../instance';
import { PlayerFields } from '../auth';

export function searchPlayers(input: string) {
  return instance.get<{ players: PlayerFields[] }>('/players/search',
    { params: { input } });
}

export function banPlayer(playerId: string) {
  return instance.post<{ status: 'ok' }>('/players/ban', { playerId });
}

import * as auth from './auth';
import * as playerTiers from './player-tiers';
import * as seasons from './seasons';
import * as gameKeys from './game-keys';
import * as newsfeed from './newsfeed';
import * as twitch from './twitch';
import * as downtime from './downtime';
import * as rewards from './rewards';
import * as goldenKeyRoll from './golden-key-roll';
import * as goldenKeys from './golden-keys';
import * as downloadLinks from './download-links';
import * as playerRewards from './player-rewards';
import * as playersBan from './players-ban';

export default {
  auth,
  playerTiers,
  seasons,
  gameKeys,
  newsfeed,
  twitch,
  downtime,
  rewards,
  goldenKeys,
  goldenKeyRoll,
  downloadLinks,
  playerRewards,
  playersBan,
};

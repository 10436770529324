import React, { CSSProperties, SyntheticEvent, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import type { DropdownMenuProps } from 'react-bootstrap/DropdownMenu';

import useInput from '../../utils/hooks/useInput';
import styled from 'styled-components';

interface Props extends DropdownMenuProps {
  style?: CSSProperties;
  'aria-labelledby'?: string;
  children?: JSX.Element[];
  filter: (filter: string, index: number) => boolean;
  setInputValue?: (value: (((prevState: (string)) =>
    (string)) | string)) => void;
}

const DropdownMenuWithFilter = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      style,
      className,
      'aria-labelledby': labeledBy,
      filter,
      setInputValue,
      ...otherProps
    },
    ref,
  ) => {
    const [filterText, setFilterText] = useInput('');

    const items = useMemo(() => {
      return React.Children.toArray(children).filter((c, i) =>
        filter(filterText, i),
      );
    }, [children, filter, filterText]);

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <SearchInputWrapper>
          <SearchInput
            autoFocus
            placeholder='Search'
            value={filterText}
            onInput={(event: SyntheticEvent<HTMLInputElement, InputEvent>) => {
              event.preventDefault();
              if (setInputValue && event.currentTarget?.value.length > 2 ) {
                setInputValue(event.currentTarget?.value);
              }
            }}
            onChange={setFilterText}
            className='m-2'
          />
        </SearchInputWrapper>
        <ItemsList className='list-unstyled'>{items}</ItemsList>
      </div>
    );
  },
);

const SearchInputWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const SearchInput = styled(Form.Control)`
  flex: 1;
`;

const ItemsList = styled.ul`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 0;
`;

export default DropdownMenuWithFilter;

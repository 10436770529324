import React from 'react';
import { Button, Container, Spinner, Col } from 'react-bootstrap';

import { useAPI } from '../../../utils/hooks/useRequest';
import api from '../../../api';

const ResendGameKeys: React.FC = () => {
  const { state: resendGameKeysState, fetch: resendGameKeysFetch } = useAPI(
    api.gameKeys.resendGameKeys,
  );

  const handleGameKeysResend = () => {
    resendGameKeysFetch(undefined);
  };

  return (
    <Container>
      <Col>
        <Button
          disabled={resendGameKeysState.status === 'IN_PROGRESS'}
          variant='success'
          className='mb-3'
          onClick={handleGameKeysResend}
        >
          {resendGameKeysState.status === 'IN_PROGRESS' ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2 mt-2"
              />
              Loading...
            </>
          ) : (
            'Resend Failed Game Keys'
          )}
        </Button>
      </Col>
      <Col className="mb-3">
        {resendGameKeysState.status === 'FULFILLED' && (
          <span style={{ color: 'green' }}>
            Successfully resend!
          </span>
        )}
        {resendGameKeysState.status === 'REJECTED' && (
          <span style={{ color: 'red' }}>
            Error: {resendGameKeysState.error.message}
          </span>
        )}
      </Col>
    </Container>
  );
};

export default ResendGameKeys;

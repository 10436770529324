import React, { useCallback } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import useInput from '../../../utils/hooks/useInput';
import { useAPI } from '../../../utils/hooks/useRequest';
import api from '../../../api';

interface Props {
  chances: {
    winningChance: number,
    lossChance: number,
  },
}

const WinLoseChances: React.FC<Props> = ({ chances }: Props) => {
  const [lossChance, setLossChance] = useInput(chances.lossChance.toString());
  const [winningChance, setWinningChance] = useInput(chances.winningChance.toString());

  const { state: updateChancesState, fetch: updateChancesFetch } = useAPI<{},
    { winningChance: number, lossChance: number }>(
    api.goldenKeys.updateChances,
  );

  const submitChances = useCallback(() => {
    updateChancesFetch({
      lossChance: Number(lossChance),
      winningChance: Number(winningChance),
    });
  }, [lossChance, winningChance, updateChancesFetch]);


  return (
    <>
      <Row className='mt-5'>
        <Col md={3}>
          <Form.Group>
            <Form.Label style={{ color: 'white' }}>Wining Chance</Form.Label>
            <Form.Control
              id='winningChance'
              type='number'
              name='winningChance'
              value={winningChance}
              onChange={setWinningChance}
            />
          </Form.Group>
        </Col>
        <Col md={{ span: 3, offset: 1 }}>
          <Form.Group>
            <Form.Label style={{ color: 'white' }}>Loss Chance</Form.Label>
            <Form.Control
              id='lossChance'
              type='number'
              name='lossChance'
              value={lossChance}
              onChange={setLossChance}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={submitChances} variant='success'
                  disabled={updateChancesState.status === 'IN_PROGRESS'}
                  className='mt-2 mb-3'>
            {updateChancesState.status === 'IN_PROGRESS' ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
                Loading...
              </>
            ) : (
              'Save'
            )}
          </Button>
          <Col>
            <Row className='mb-3'>
              {(updateChancesState.status === 'REJECTED') ? (
                <div style={{ color: 'red' }}>
                  {updateChancesState.error.message}
                </div>
              ) : ''}
            </Row>
          </Col>
        </Col>
      </Row>

    </>
  );
};

export default WinLoseChances;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import Datepicker from 'react-datepicker';
import { useAPI } from '../../../utils/hooks/useRequest';
import BlurOverlay from '../../../components/BlurOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import styled from 'styled-components';
import _omit from 'lodash/omit';

import 'react-datepicker/dist/react-datepicker.min.css';

import Loader from '../../../components/Loader';
import api from '../../../api';
import { useChanged } from '../../../utils/hooks/useChanged';
import type { DowntimeItem } from '../../../api/downtime';

interface BaseProps {
  onClose: () => void;
}

interface EditProps {
  downtimeItem: DowntimeItem;
  onUpdated: (downtimeItem: DowntimeItem) => void;
  onDeleted: (downtimeItem: DowntimeItem) => void;
}

interface AddProps {
  downtimeItem?: undefined;
  onCreated: (newsfeed: DowntimeItem[]) => void;
}

const formSchema = yup
  .object({
    id: yup.string(),
    description: yup.string().required(),
    startsAt: yup.number().positive().required(),
    endsAt: yup.number().moreThan(yup.ref('startsAt')).required(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type Props = BaseProps & (EditProps | AddProps);

const EditDowntimeItemPopup: React.FC<Props> = ({ onClose, ...props }) => {
  const { downtimeItem } = props;

  const [isDeleteConfirmationActive, setDeleteConfirmationState] = useState(
    false,
  );

  const { state: updateDowntimeItemState, fetch: updateDowntimeItem } = useAPI(
    api.downtime.updateItem,
  );
  const updateDowntimeItemStatusChanged = useChanged(
    updateDowntimeItemState.status,
  );

  const { state: createDowntimeItemState, fetch: createDowntimeItem } = useAPI(
    api.downtime.createItem,
  );
  const createDowntimeItemStatusChanged = useChanged(
    createDowntimeItemState.status,
  );

  const { state: deleteDowntimeItemStatus, fetch: deleteDowntimeItem } = useAPI(
    api.downtime.deleteItem,
  );
  const deleteDowntimeItemStateChanged = useChanged(
    deleteDowntimeItemStatus.status,
  );

  const formInitialValues = useMemo<FormType>(() => {
    if (downtimeItem) {
      return {
        id: downtimeItem.id,
        description: downtimeItem.description,
        startsAt: downtimeItem.startsAt,
        endsAt: downtimeItem.endsAt,
      };
    }

    const curDate = +new Date();

    return {
      id: '',
      description: '',
      startsAt: curDate,
      endsAt: curDate,
    };
  }, [downtimeItem]);

  const handleFormikSubmit = useCallback(
    (values: FormType) => {
      if (downtimeItem) {
        updateDowntimeItem({
          id: downtimeItem.id,
          data: _omit(values, ['id']),
        });
      } else {
        createDowntimeItem(_omit(values, ['id']));
      }
    },
    [downtimeItem, updateDowntimeItem, createDowntimeItem],
  );

  const handleDelete = useCallback(() => {
    if (!downtimeItem) return;
    deleteDowntimeItem(downtimeItem.id);
  }, [downtimeItem, deleteDowntimeItem]);

  useEffect(() => {
    if (
      props.downtimeItem &&
      updateDowntimeItemStatusChanged &&
      updateDowntimeItemState.status === 'FULFILLED'
    ) {
      props.onUpdated(updateDowntimeItemState.data.downtime);
      onClose();
    }
  }, [
    props,
    updateDowntimeItemStatusChanged,
    updateDowntimeItemState,
    onClose,
  ]);

  useEffect(() => {
    if (
      !props.downtimeItem &&
      createDowntimeItemStatusChanged &&
      createDowntimeItemState.status === 'FULFILLED'
    ) {
      props.onCreated(createDowntimeItemState.data.downtimes);
      onClose();
    }
  }, [
    props,
    createDowntimeItemStatusChanged,
    createDowntimeItemState,
    onClose,
  ]);

  useEffect(() => {
    if (
      props.downtimeItem &&
      deleteDowntimeItemStateChanged &&
      deleteDowntimeItemStatus.status === 'FULFILLED'
    ) {
      props.onDeleted(props.downtimeItem);
      onClose();
    }
  }, [
    props,
    deleteDowntimeItemStateChanged,
    deleteDowntimeItemStatus,
    onClose,
  ]);

  return (
    <>
      <Formik<FormType>
        validationSchema={formSchema}
        initialValues={formInitialValues}
        onSubmit={handleFormikSubmit}
        validateOnMount
      >
        {({
          values,
          setFieldValue,
          errors,
          submitForm,
          handleChange,
          isValid,
        }) => {
          return (
            <>
              <Modal.Header>
                <b>
                  {downtimeItem ? <>Edit downtime item</> : <>Add new item</>}
                </b>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  {!!values.id && (
                    <Form.Group>
                      <Form.Label>ID</Form.Label>
                      <Form.Control
                        plaintext
                        readOnly
                        type='text'
                        defaultValue={values.id}
                      />
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as='textarea'
                      name='description'
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                      rows={5}
                    />
                  </Form.Group>
                  <Form.Row>
                    <Col md={6}>
                      <DatepickerFormGroup>
                        <Form.Label>Start date</Form.Label>
                        <Datepicker
                          customInput={
                            <Form.Control isInvalid={!!errors.startsAt} />
                          }
                          selected={new Date(values.startsAt)}
                          onChange={(v) => {
                            if (v && !Array.isArray(v)) {
                              setFieldValue('startsAt', +v);
                            }
                          }}
                          selectsStart
                          startDate={new Date(values.startsAt)}
                          endDate={
                            values.endsAt ? new Date(values.endsAt) : undefined
                          }
                          showTimeInput
                          dateFormat='M/d/yyyy h:mm aa'
                        />
                      </DatepickerFormGroup>
                    </Col>
                    <Col md={6}>
                      <DatepickerFormGroup>
                        <Form.Label>End date</Form.Label>
                        <Datepicker
                          customInput={
                            <Form.Control isInvalid={!!errors.endsAt} />
                          }
                          selected={
                            values.endsAt ? new Date(values.endsAt) : undefined
                          }
                          onChange={(v) => {
                            if (v && !Array.isArray(v)) {
                              setFieldValue('endsAt', +v);
                            }
                          }}
                          selectsEnd
                          startDate={new Date(values.startsAt)}
                          endDate={
                            values.endsAt ? new Date(values.endsAt) : undefined
                          }
                          minDate={new Date(values.startsAt)}
                          showTimeInput
                          dateFormat='M/d/yyyy h:mm aa'
                        />
                      </DatepickerFormGroup>
                    </Col>
                  </Form.Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                {!!downtimeItem &&
                  (isDeleteConfirmationActive ? (
                    <Button
                      variant='danger'
                      className='mr-auto'
                      onClick={handleDelete}
                    >
                      Press again to confirm deletion
                    </Button>
                  ) : (
                    <Button
                      variant='danger'
                      className='mr-auto'
                      onClick={() => setDeleteConfirmationState(true)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      &nbsp;Delete
                    </Button>
                  ))}
                <Button variant='secondary' onClick={onClose}>
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp;Cancel
                </Button>
                <Button
                  variant='primary'
                  onClick={submitForm}
                  disabled={!isValid}
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp;Save
                </Button>
              </Modal.Footer>

              {(updateDowntimeItemState.status === 'IN_PROGRESS' ||
                createDowntimeItemState.status === 'IN_PROGRESS' ||
                deleteDowntimeItemStatus.status === 'IN_PROGRESS') && (
                <BlurOverlay>
                  <Loader />
                </BlurOverlay>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

const DatepickerFormGroup = styled(Form.Group)`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

type WrapperBaseProps = BaseProps & {
  parentUrl: string;
};

type WrapperEditProps = Omit<EditProps, 'downtimeItem'> & {
  downtimeList: DowntimeItem[];
};

type WrapperAddProps = Omit<AddProps, 'downtimeItem'> & {
  downtimeList?: undefined;
};

type WrapperProps = WrapperBaseProps & (WrapperEditProps | WrapperAddProps);

const EditDowntimeItemPopupWrapper: React.FC<WrapperProps> = ({
  parentUrl,
  ...props
}) => {
  const { onClose, downtimeList } = props;

  const match = useRouteMatch<{ downtimeItemId: string }>();

  const downtimeItem = useMemo<DowntimeItem | undefined>(() => {
    if (!downtimeList || !match) {
      return undefined;
    }
    return downtimeList.find((item) => item.id === match.params.downtimeItemId);
  }, [downtimeList, match]);

  const renderPopup = useCallback(
    (children?: React.ReactNode) => {
      return (
        <Modal
          show={!!match}
          onHide={onClose}
          backdrop='static'
          keyboard={false}
        >
          {children}
        </Modal>
      );
    },
    [match, onClose],
  );

  if (!match) {
    return renderPopup();
  }

  if (props.downtimeList) {
    if (downtimeItem) {
      return renderPopup(
        <EditDowntimeItemPopup downtimeItem={downtimeItem} {...props} />,
      );
    }

    return <Redirect to={parentUrl} />;
  }

  return renderPopup(<EditDowntimeItemPopup {...props} />);
};

export default EditDowntimeItemPopupWrapper;

import instance from '../instance';

export interface PlayerReward {
  id: string;
  title: string;
  image: string | null;
  variants: {
    id: string;
    title: string;
    image?: string | null;
    quantity: number;
  }[];
}

export async function listRewards() {
  return instance.get<{ rewards: PlayerReward[] }>(`/rewards`);
}

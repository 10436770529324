import axios, { AxiosError, AxiosResponse } from 'axios';
import _has from 'lodash/has';

import store from '../redux';
import { selectTokens } from '../redux/auth/selectors';
import { actions } from '../redux/auth/AuthReducer';

export interface ErrorResponse {
  error: {
    type: string;
    status: string;
    message: string;
    payload: unknown;
  };
}

const instance = axios.create({
  baseURL: process.env.API_ENDPOINT,
});

instance.interceptors.request.use((config) => {
  const tokens = selectTokens(store.getState());

  if (tokens.data) {
    config.headers = {
      ...(config.headers as Record<string, string>),
      Authorization: tokens.data.id,
    };
  }

  return config;
});

instance.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.response && isCustomErrorResponse(error.response.data)) {
    throw new Error(error.response.data.error.message);
  }
  throw error;
});

instance.interceptors.response.use(undefined, async (error: AxiosError) => {
  if (error.response?.status === 401) {
    const res = await actions.refreshTokens();

    if (!res?.data) {
      actions.logout();
    }

    if (res?.data.tokens) {
      return instance(error.config);
    }
    throw error;
  } else {
    throw error;
  }
});

function isCustomErrorResponse(data: unknown): data is ErrorResponse {
  return _has(data, 'error.type');
}

export default instance;

export function isAxiosError<T>(e: Error): e is AxiosError<T> {
  return 'response' in e;
}

export function isAxiosResponse(o: object): o is AxiosResponse {
  return 'data' in o;
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { Link, Route, useHistory, useRouteMatch } from 'react-router-dom';
import urlJoin from 'url-join';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import DataTable from 'react-data-table-component';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import styled from 'styled-components';

import EditDownloadLinkPopup from './EditDownloadLinkPopup';
import { useAPI } from '../../../utils/hooks/useRequest';
import api from '../../../api';
import { DownloadLink } from '../../../api/download-links';
import Loader from '../../../components/Loader';

const DownloadLinks: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [selectedDownloadLink, setSelectedDownloadLink] =
    useState<Omit<DownloadLink, 'updatedAt' | 'updatedBy'> | null>(null);
  const [downloadLinks, setDownloadLinks] =
    useState<Omit<DownloadLink, 'updatedAt' | 'updatedBy'>[]>([]);
  const [types, setTypes] = useState<string[]>([]);

  const addNewItemButton = useMemo(
    () => (
      <Link to={urlJoin(match.url, '/new')}>
        <Button variant='success' className='mt-2 mb-2'>
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;Add new item
        </Button>
      </Link>
    ),
    [match],
  );

  const closePopup = useCallback(() => {
    history.push(match.url);
  }, [history, match]);

  const onAdd = (newItem: Omit<DownloadLink, 'updatedAt' | 'updatedBy'>) => {
    saveDownloadLinkFetch({
      url: newItem.url,
      isCDNUrl: newItem.isCDNUrl,
      type: newItem.id,
    });
    setDownloadLinks([...downloadLinks, newItem]);
  };

  const onEdit = (newItem: Omit<DownloadLink, 'updatedAt' | 'updatedBy'>) => {
    saveDownloadLinkFetch({
      url: newItem.url,
      isCDNUrl: newItem.isCDNUrl,
      type: newItem.id,
    });
    setDownloadLinks(downloadLinks.map((el) => {
      if (el.id === newItem.id) {
        return {
          id: newItem.id,
          url: newItem.url,
          isCDNUrl: newItem.isCDNUrl,
        };
      }
      return el;
    }));
  };

  const { state: downloadLinksState, fetch: downloadLinksFetch } = useAPI(
    api.downloadLinks.listDownloadLinks,
  );

  const { state: saveDownloadLinkState, fetch: saveDownloadLinkFetch } = useAPI(
    api.downloadLinks.saveDownloadLink,
  );

  useEffect(() => {
    downloadLinksFetch(undefined);
  }, [downloadLinksFetch]);

  useEffect(() => {
    if (downloadLinksState.status === 'FULFILLED') {
      setDownloadLinks(downloadLinksState.data.downloadLinks);
      setTypes(downloadLinksState.data.types);
    }
  }, [setDownloadLinks, downloadLinksState]);

  useEffect(() => {
    if (saveDownloadLinkState.status === 'FULFILLED') {
      history.push(match.url);
    }
  }, [saveDownloadLinkState.status, history, match.url]);

  if (downloadLinksState.status !== 'FULFILLED') {
    return <Loader />;
  }

  return (
    <>
      <Container>
        {addNewItemButton}
        <DataTable<Omit<DownloadLink, 'updatedAt' | 'updatedBy'>>
          title='Download Links'
          responsive
          striped
          highlightOnHover
          theme='dark'
          columns={[
            {
              name: 'Type',
              selector: ({ id }) => id,
              sortable: true,
            },
            {
              name: 'Link',
              selector: ({ url }) => url,
              sortable: true,
            },
            {
              name: 'CDN Url',
              selector: ({ isCDNUrl }) => isCDNUrl,
              format: ({ isCDNUrl }) => isCDNUrl ? 'yes' : 'no',
              sortable: true,
            },
            {
              name: '',
              right: true,
              cell: (item) => (
                <>
                  <Link
                    to={urlJoin(match.url, `/${item.id}/edit`)}
                    title='Edit'
                  >
                    <TableButton
                      variant='primary'
                      onClick={() => setSelectedDownloadLink(item)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </TableButton>
                  </Link>
                </>
              ),
            },
          ]} data={downloadLinks} />
        {addNewItemButton}

      </Container>
      <>
        <Route exact path={urlJoin(match.path, '/:downloadLinkId/edit')}>
          {({ match: curMatch }) => selectedDownloadLink && (
            <Modal
              centered
              show={!!curMatch}
              onHide={closePopup}
              keyboard
              size='xl'
            >
              <EditDownloadLinkPopup
                downloadLinks={downloadLinks}
                onClose={closePopup}
                downloadLinkItem={selectedDownloadLink}
                onUpdated={onEdit}
                isLoading={saveDownloadLinkState.status === 'IN_PROGRESS'}
                isError={saveDownloadLinkState.status === 'REJECTED'}
                error={saveDownloadLinkState?.error?.message}
                types={types}
              />
            </Modal>
          )}
        </Route>
        <Route exact path={urlJoin(match.path, '/new')}>
          {({ match: curMatch }) => (
            <Modal
              centered
              show={!!curMatch}
              onHide={closePopup}
              keyboard
              size='xl'
            >
              <EditDownloadLinkPopup
                downloadLinks={downloadLinks}
                onClose={closePopup}
                onCreated={onAdd}
                isLoading={saveDownloadLinkState.status === 'IN_PROGRESS'}
                isError={saveDownloadLinkState.status === 'REJECTED'}
                error={saveDownloadLinkState?.error?.message}
                types={types}
              />
            </Modal>
          )}
        </Route>
      </>
    </>
  );
};

const TableButton = styled(Button)`
  white-space: nowrap;
`;

export default DownloadLinks;

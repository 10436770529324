import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import {
  selectLoggedIn,
  selectTokens,
  selectUser,
} from '../../redux/auth/selectors';
import AuthService from './AuthService';
import { actions } from '../../redux/auth/AuthReducer';
import Loader from '../../components/Loader';
import { useChanged } from '../hooks/useChanged';

export const cognitoStateSchema = yup
  .object({
    redirect_to: yup.string().required(),
  })
  .required();

const AuthGuard: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoggedIn = useSelector(selectLoggedIn);

  const user = useSelector(selectUser);
  const userChanged = useChanged(user.fetched);
  const userErrorChanged = useChanged(user.error);

  const tokens = useSelector(selectTokens);

  const redirectToLogin = useCallback(() => {
    dispatch(actions.reset());
    history.replace('/auth');
  }, [history, dispatch]);

  useEffect(() => {
    const curTokens = AuthService.getTokens();
    dispatch(actions.setTokens(curTokens));
  }, [dispatch]);

  useEffect(() => {
    if (tokens.fulfilled && tokens.data) {
      dispatch(actions.fetchSelfUser());
    }
  }, [tokens, dispatch]);

  useEffect(() => {
    if (tokens.fulfilled && !tokens.data) {
      redirectToLogin();
    }
  }, [tokens, redirectToLogin]);

  useEffect(() => {
    if (userErrorChanged && user.error) {
      redirectToLogin();
    }
  }, [userErrorChanged, user, redirectToLogin]);

  useEffect(() => {
    if (userChanged && user.fetched && !isLoggedIn) {
      redirectToLogin();
    }
  }, [userChanged, user, isLoggedIn, redirectToLogin]);

  if (isLoggedIn) {
    return <>{children}</>;
  }

  return <Loader />;
};

export default AuthGuard;

import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';

import { useAPI } from '../../utils/hooks/useRequest';
import api from '../../api';
import { Breakpoints } from '../../utils/responsive';
import { setUsernameToLS } from './index';

const formSchema = yup
  .object({
    email: yup.string().required(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const ResetPasswordForm = () => {
  const history = useHistory();

  const { state: resetPasswordState, fetch: resetPasswordFetch } = useAPI(
    api.auth.webResetPassword,
  );

  const formInitialValues = useMemo<FormType>(() => (
    { email: '' }
  ), []);

  const handlePasswordReset = (email: string) => {
    setUsernameToLS(email);
    resetPasswordFetch(email);
  };

  useEffect(() => {
    if (resetPasswordState.status === 'FULFILLED') {
      const encodedDest = Buffer.from(resetPasswordState.data.destination)
        .toString('base64');
      history.replace(`/auth/change-password?destination=
      ${encodeURI(encodedDest)}`);
    }
  }, [resetPasswordState, history]);

  return (
    <Container>
      <Formik<FormType>
        validationSchema={formSchema}
        initialValues={formInitialValues}
        onSubmit={({ email }) =>
          handlePasswordReset(email)}
      >
        {({
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => {
          return (
            <>
              <Input id="email" type="email" placeholder="Email"
                     isInvalid={!!errors.email && !!touched.email}
                     onChange={event =>
                       setFieldValue('email', event.target.value)} />
              {errors.email && touched.email && (
                <FieldError>* {errors.email}</FieldError>
              )}
              <LoginButton
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  return submitForm();
                }}
                disabled={resetPasswordState.status === 'IN_PROGRESS'}>
                {resetPasswordState.status === 'IN_PROGRESS' ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      color='white'
                      style={{ marginRight: 10, marginBottom: 5 }}
                    />
                    Loading...
                  </>
                ) : (
                  'Reset Password'
                )}
              </LoginButton>
              {(resetPasswordState.status === 'REJECTED') ? (
                (resetPasswordState.error.message === 'Too many requests') ? (
                  <Error>
                    We’re experiencing a ton of requests right now.
                    Please try again in a moment.
                  </Error>
                ) : (
                  <Error>{resetPasswordState.error.message}</Error>
                )
              ) : ''}
            </>
          );
        }}
      </Formik>

      <OrLabel />
      <LoginLink to='/auth/sign-up'>
        Create Free Account
      </LoginLink>
    </Container>
  );
};

const Error = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

const FieldError = styled(Error)`
  margin-top: -10px;
  margin-bottom: 5px;
`;

const Container = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  width: 100%;
`;

const LoginButton = styled.button`
  border: none;
  background-image: linear-gradient(to left, #62451f, #b17e33);
  color: #ffffff;
  padding: 2px;
  font-size: 24px;
  font-weight: 300;
  margin-top: 5px;
  cursor: pointer;

  &:hover, &:focus {
    background-image: linear-gradient(to left, #b17e33, #d2a75d);
    outline: none;
  }
`;

const Input = styled.input<{ isInvalid: boolean }>`
  border: none;
  padding: 3px 10px;
  outline: ${({ isInvalid }) => isInvalid ? '1px solid red' : 'none'};
  background-color: #151515;
  color: white;
  margin-bottom: 14px;

  &::placeholder {
    color: white;
  }

  &:active, &:focus {
    outline: 1px solid white;
    background: #222222;
    color: white;
    border: none;
    font-size: 16px;
  }
`;

const OrLabel = styled.div`
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;

  &::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 25%;
    border-bottom: 1px solid #484c5c;
    width: 50%;
  }

  &::after {
    content: "OR";
    position: absolute;
    top: -12px;
    left: 45%;
    padding: 0 10px;
    background: #222222;

    @media(max-width: ${Breakpoints.iphone5}px) {
      left: 41%;
    }
  }
`;

const LoginLink = styled(Link)`
  color: white;

  &:hover, &:focus {
    color: white;
    text-decoration: underline;
    outline: none;
  }
`;

export default ResetPasswordForm;

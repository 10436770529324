import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { GoldenKeyItem } from './index';

interface Props {
  goldenKeyItem: GoldenKeyItem;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteGoldenKeyItemPopup: React.FC<Props> = ({goldenKeyItem, onClose, onDelete}) => {
  return (
    <>
      <Modal.Header>
        <b>Are you sure?</b>
      </Modal.Header>
      <Modal.Body>
        You are about to delete golden key item{' '}
        <b>&quot;{goldenKeyItem.rewardTitle}&quot;</b>. Once you click
        &quot;Yes&quot;, this cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          No
        </Button>
        <Button variant='primary' onClick={onDelete}>
          Yes
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DeleteGoldenKeyItemPopup;

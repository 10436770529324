import instance from '../instance';
import type { PlayerFields } from '../auth';

export interface DowntimeItem {
  id: string;
  description: string;
  startsAt: number;
  endsAt: number;
  createdBy: PlayerFields;
  createdAt: number;
  updatedBy: PlayerFields;
  updatedAt: number;
}

export async function listItems() {
  return instance.get<{ downtimes: DowntimeItem[] }>(`/downtimes`);
}

export async function deleteItem(id: DowntimeItem['id']) {
  return instance.delete<{ status: 'ok' }>(`/downtimes/${id}`);
}

export async function updateItem({
  id,
  data,
}: {
  id: DowntimeItem['id'];
  data: {
    description: string;
    startsAt: number;
    endsAt: number;
  };
}) {
  return instance.post<{ downtime: DowntimeItem }>(`/downtimes/${id}`, data);
}

export async function createItem(data: {
  description: string;
  startsAt: number;
  endsAt: number;
}) {
  return instance.post<{ downtimes: DowntimeItem[] }>(`/downtimes`, data);
}

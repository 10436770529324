import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Form, Spinner } from 'react-bootstrap';

import FullWidthDropdown from '../../../components/FullWidthDropdown';
import DropdownMenuWithFilter from '../../../components/DropdownMenuWithFilter';
import { PlayerFields } from '../../../api/auth';
import { useAPI } from '../../../utils/hooks/useRequest';
import api from '../../../api';

const PlayersBan: React.FC = () => {
  const [player, setPlayer] = useState<PlayerFields | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [playersList, setPlayersList] = useState<PlayerFields[]>([]);

  const { state: playersListState, fetch: playersListFetch } = useAPI(
    api.playersBan.searchPlayers,
  );

  const { state: banPlayerState, fetch: banPlayerFetch } = useAPI(
    api.playersBan.banPlayer,
  );

  const searchForPlayers = useCallback(() => {
    playersListFetch(searchInput);
  }, [searchInput, playersListFetch]);

  const banPlayer = (id: string) => {
    banPlayerFetch(id);
  };

  useEffect(() => {
    if (playersListState.status === 'FULFILLED') {
      setPlayersList(playersListState.data.players);
    }
  }, [playersListState]);

  useEffect(() => {
    if (banPlayerState.status === 'FULFILLED') {
      setPlayer(null);
      setPlayersList([]);
    }
  }, [banPlayerState]);

  useEffect(() => {
    if (searchInput) {
      searchForPlayers();
    } else {
      setPlayer(null);
      setPlayersList([]);
    }
  }, [searchInput, searchForPlayers]);

  const getSearchStatus = useCallback(() => {
    if (playersListState.status === 'FULFILLED' &&
      !playersListState?.data?.players.length) {
      return 'Nothing was found :(';
    }
    if (playersListState.status === 'IN_PROGRESS') {
      return 'Loading...';
    }
    return 'Start typing...';
  }, [playersListState?.data?.players.length, playersListState.status]);

  return (
    <>
      <Container>
        <Col lg={6}>
          <Form.Label style={{ color: 'white' }}>
            Search for players to ban
          </Form.Label>
          <Form.Group>
            <FullWidthDropdown>
              <Dropdown.Toggle
                variant='light'
                disabled={playersListState.status === 'IN_PROGRESS'}>
                {player ? (
                  <>{player.username} ({player.id})</>
                ) : (
                  <>{getSearchStatus()}</>
                )}
                {playersListState.status === 'IN_PROGRESS' && (
                  <Spinner
                    as="div"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginLeft: '20rem' }}
                  />
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={DropdownMenuWithFilter}
                setInputValue={setSearchInput}
                filter={(text: string, i: number) =>
                  (playersList[i].username
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    playersList[i].id
                      .toLowerCase()
                      .includes(text.toLowerCase())
                    || false)}
              >
                {playersList.map((r: PlayerFields) => (
                  <Dropdown.Item
                    key={r.id}
                    onClick={() =>
                      setPlayer(r)
                    }
                  >
                    <>{r.username} ({r.id})</>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </FullWidthDropdown>
          </Form.Group>
          <Button
            disabled={!player || banPlayerState.status === 'IN_PROGRESS'}
            variant='success'
            className='mb-3'
            onClick={() => {
              if (!player) return;
              banPlayer(player?.id);
            }}
          >
            {banPlayerState.status === 'IN_PROGRESS' ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
                Loading...
              </>
            ) : (
              'Ban'
            )}
          </Button>
        </Col>
        <Col>
          {banPlayerState.status === 'FULFILLED' && (
            <span style={{ color: 'green' }}>Successfully banned!</span>
          )}
          {banPlayerState.status === 'REJECTED' && (
            <span style={{ color: 'red' }}>Error: {banPlayerState.error.message}</span>
          )}
        </Col>
      </Container>
    </>
  );
};

export default PlayersBan;

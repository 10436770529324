import styled from 'styled-components';
import React from 'react';

import type { PlayerReward } from '../../api/rewards';

export default function formatPlayerReward(
  reward: PlayerReward,
): React.ReactNode {
  return (
    <Item>
      {reward.image && <ItemImage src={reward.image} />}
      {reward.title}
    </Item>
  );
}

const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const ItemImage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 8px;
`;

import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import * as yup from 'yup';
import { Formik } from 'formik';

import { useAPI } from '../../utils/hooks/useRequest';
import api from '../../api';
import { getUsernameFromLS } from './index';

const formSchema = yup
  .object({
    code: yup.string().required(),
    pwd: yup.string().required('password is required'),
    repeatPwd: yup.string().required('repeating password is required'),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const ChangePasswordForm = () => {
  const formInitialValues = useMemo<FormType>(() => (
    { code: '', pwd: '', repeatPwd: '' }
  ), []);

  const history = useHistory();
  const dest = Buffer.from(new URLSearchParams(history.location.search)
    .get('destination') ?? '', 'base64').toString('utf8');

  const { state: changePasswordState, fetch: changePasswordFetch } = useAPI(
    api.auth.webChangePassword,
  );

  const handlePasswordChange = (code: string, pwd: string) => {
    const username = getUsernameFromLS();
    if (username) {
      changePasswordFetch({ code, pwd, username });
    }
  };

  useEffect(() => {
    if (!getUsernameFromLS() || !dest) {
      history.push('/auth/log-in');
    }
  }, [history, dest]);

  useEffect(() => {
    if (changePasswordState.status === 'FULFILLED') {
      history.replace('/auth/log-in');
    }
  }, [changePasswordState.status, history]);

  return (
    <Container>
      <Formik<FormType>
        validationSchema={formSchema}
        initialValues={formInitialValues}
        onSubmit={({ code, pwd }) =>
          handlePasswordChange(code, pwd)}
      >
        {({
            setFieldValue,
            submitForm,
            values,
            errors,
            touched,
          }) => {
          const passwordRequirements =
            [
              {
                id: 0, label: 'Contains at least 8 characters',
                condition: (values.pwd.length > 8),
              },
              {
                id: 1, label: 'Contains numbers',
                condition: (/\d/.test(values.pwd)),
              },
              {
                id: 2, label: 'Contains special character',
                condition: (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
                  .test(values.pwd)),
              },
              {
                id: 3, label: 'Contains uppercase letters',
                condition: (
                  values.pwd !== values.pwd.toLowerCase()
                ),
              },
              {
                id: 4, label: 'Contains lowercase letters',
                condition: (
                  values.pwd !== values.pwd.toUpperCase()
                ),
              },
              {
                id: 5, label: 'Passwords are equal',
                condition: (values.repeatPwd &&
                  values.pwd &&
                  values.repeatPwd === values.pwd),
              },
            ];
          return (
            <>
              <Destination>Verification code was sent to {dest}</Destination>
              <Input id="code"
                     type="text"
                     placeholder="Code"
                     isInvalid={!!errors.code && !!touched.code}
                     onChange={event =>
                       setFieldValue('code', event.target.value)} />
              {errors.code && touched.code && (
                <FieldError>* {errors.code}</FieldError>
              )}
              <Input id="newPassword"
                     type="password"
                     placeholder="New Password"
                     isInvalid={!!errors.pwd && !!touched.pwd}
                     onChange={event =>
                       setFieldValue('pwd', event.target.value)} />
              {errors.pwd && touched.pwd && (
                <FieldError>* {errors.pwd}</FieldError>
              )}
              <Input id="newPasswordRepeat"
                     type="password"
                     placeholder="Repeat New Password"
                     isInvalid={!!errors.repeatPwd && !!touched.repeatPwd}
                     onChange={event =>
                       setFieldValue('repeatPwd', event.target.value)} />
              {errors.repeatPwd && touched.repeatPwd && (
                <FieldError>* {errors.repeatPwd}</FieldError>
              )}
              <LoginButton
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  if (values.code && values.pwd &&
                    !passwordRequirements.every((el) => el.condition)) {
                    return;
                  }
                  return submitForm();
                }}
                disabled={changePasswordState.status === 'IN_PROGRESS'}>
                {changePasswordState.status === 'IN_PROGRESS' ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      color='white'
                      style={{ marginRight: 10, marginBottom: 5 }}
                    />
                    Loading...
                  </>
                ) : (
                  'Change Password'
                )}
              </LoginButton>
              {(changePasswordState.status === 'REJECTED') ? (
                (changePasswordState.error.message === 'Too many requests') ? (
                  <ErrorMessage>
                    We’re experiencing a ton of requests right now.
                    Please try again in a moment.
                  </ErrorMessage>
                ) : (
                  <ErrorMessage>
                    {changePasswordState.error.message}
                  </ErrorMessage>
                )
              ) : ''}
              <PasswordRequirements>
                {(values.pwd || values.repeatPwd) &&
                passwordRequirements.map((el) => (
                  <div
                    key={el.id}
                    style={{ color: `${el.condition ? '#49b653' : 'red'}` }}>
                    <FontAwesomeIcon
                      icon={el.condition ? faCheckCircle : faTimesCircle}
                      style={{ marginRight: 5 }} />
                    {el.label}
                  </div>
                ))}
              </PasswordRequirements>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

const Destination = styled.div`
  text-align: center;
  color: white;
  margin-bottom: 10px;
`;

const PasswordRequirements = styled.div`
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

const FieldError = styled(ErrorMessage)`
  margin-top: -10px;
  margin-bottom: 5px;
`;

const Container = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  width: 100%;
`;

const Input = styled.input<{ isInvalid: boolean }>`
  border: none;
  padding: 3px 10px;
  outline: ${({ isInvalid }) => isInvalid ? '1px solid red' : 'none'};
  background-color: #151515;
  color: white;
  margin-bottom: 14px;

  &::placeholder {
    color: white;
  }

  &:active, &:focus {
    outline: 1px solid white;
    background: #222222;
    color: white;
    border: none;
    font-size: 16px;
  }
`;

const LoginButton = styled.button`
  border: none;
  background-image: linear-gradient(to left, #62451f, #b17e33);
  color: #ffffff;
  padding: 2px;
  font-size: 24px;
  font-weight: 300;
  margin-top: 5px;
  cursor: pointer;

  &:hover, &:focus {
    background-image: linear-gradient(to left, #b17e33, #d2a75d);
    outline: none;
  }
`;

export default ChangePasswordForm;

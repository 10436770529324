import moment from 'moment';
import * as yup from 'yup';

import { refreshTokens } from '../../api/auth';
import { actions } from '../../redux/auth/AuthReducer';
import store from '../../redux/index';

const COGNITO_TOKENS = 'ag_tokens';
const USERNAME = 'username';

const cognitoTokensSchema = yup
  .object({
    access: yup.string().required(),
    id: yup.string().required(),
    refresh: yup.string().required(),
    expiresIn: yup.number().positive().required(),
  })
  .required();

export type CognitoTokens = yup.InferType<typeof cognitoTokensSchema>;

export default class AuthService {
  static getTokens(): CognitoTokens | undefined {
    const tokensStr = localStorage.getItem(COGNITO_TOKENS);
    if (!tokensStr) {
      return undefined;
    }
    try {
      const tokens = JSON.parse(tokensStr) as unknown;
      if (!cognitoTokensSchema.isValidSync(tokens)) {
        throw new Error('Cognito tokens have invalid format');
      }

      return tokens;
    } catch (e) {
      console.error('Unable to parse local storage tokens:', e);
    }
    return undefined;
  }

  static setTokens({
                     access,
                     id,
                     refresh,
                     expiresIn,
                   }: {
    access: string;
    id: string;
    refresh: string;
    expiresIn: string;
  }): void {
    const tokens: CognitoTokens = {
      access,
      id,
      refresh,
      expiresIn: moment().add(expiresIn, 'seconds').valueOf(),
    };

    localStorage.setItem(COGNITO_TOKENS, JSON.stringify(tokens));
  }

  static logout() {
    localStorage.removeItem(COGNITO_TOKENS);
    localStorage.removeItem(USERNAME);
  }

  static refresh() {
    const refreshToken = this.getTokens()?.refresh;
    if (refreshToken) {
      return refreshTokens(refreshToken)
        .then((res => {
          if (res.status === 200) {
            this.setTokens({
              ...res.data.tokens,
              expiresIn: String(res.data.tokens.expiresIn),
            });
            store.dispatch(actions.setTokens(res.data.tokens));

            return res;
          }
          return Promise.reject();
        }));
    }
    return null;
  }
}

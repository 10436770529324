export const Breakpoints = {
    xs: 576,
    xsDown: 575.98,
    md: 768,
    mdDown: 767.98,
    lg: 992,
    lgDown: 991.98,
    xl: 1200,
    xlDown: 1199.98,
    iphone5: 320,
} as const;

import { createSelector } from 'reselect';

import { RootState } from '../index';

export function selectAuth(state: RootState): RootState['auth'] {
    return state.auth;
}

export const selectUser = createSelector([selectAuth], (auth) => auth.user);

export const selectUserData = createSelector([selectUser], (user) => {
    if (!user.data) {
        throw new Error('Error selecting user data: user is not logged in');
    }
    return user.data;
});

export const selectLoggedIn = createSelector(
    [selectUser],
    (user) => !!user.data,
);

export const selectTokens = createSelector([selectAuth], (auth) => auth.tokens);

import moment, { MomentInput } from 'moment';
import numeral from 'numeral';
import countdown from 'countdown';

import type { Season } from '../../api/seasons';
import type { PlayerTier } from '../../api/player-tiers';

type DateTime = countdown.DateTime;

export function formatDate(input: MomentInput): string {
  return moment(input).format('M/D/YYYY');
}

export function formatDateTime(input: MomentInput): string {
  return moment(input).format('M/D/YYYY HH:mm:ss');
}

export function formatSeason(season: Season) {
  return `${season.Description} (${formatDate(
    season.StartDateTime,
  )} - ${formatDate(season.EndDateTime)})`;
}

export function formatPlayerTier(tier: PlayerTier): string {
  return `${tier.name} (${tier.topPercentage}%)`;
}

export function formatPrice(price: number): string {
  return numeral(price).format('$0,0[.]00');
}

export function formatTimeDuration(duration: DateTime): string {
  return countdown(
    duration,
    undefined,
    // eslint-disable-next-line no-bitwise
    countdown.MONTHS |
      countdown.DAYS |
      countdown.HOURS |
      countdown.MINUTES |
      countdown.SECONDS,
    2,
  ).toString();
}

import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import * as yup from 'yup';
import { Formik } from 'formik';

import { useAPI } from '../../utils/hooks/useRequest';
import api from '../../api';
import { setUsernameToLS } from './index';
import { Breakpoints } from '../../utils/responsive';

const formSchema = yup
  .object({
    username: yup.string().required(),
    pwd: yup.string().required('password is required'),
    email: yup.string().email().required(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const SignUpForm = () => {
  const formInitialValues = useMemo<FormType>(() => (
    { username: '', pwd: '', email: '' }
  ), []);

  const history = useHistory();

  const { state: signUpState, fetch: signUpFetch } = useAPI(
    api.auth.webSignUp,
  );

  const handleSignUp = (username: string, pwd: string, email: string) => {
    setUsernameToLS(username);
    signUpFetch({ username, pwd, email });
  };

  useEffect(() => {
    if (signUpState.status === 'FULFILLED') {
      history.replace('/auth/confirm-email');
    }
  }, [signUpState.status, history]);

  return (
    <Container>
      <Formik<FormType>
        validationSchema={formSchema}
        initialValues={formInitialValues}
        onSubmit={({ username, pwd, email }) =>
          handleSignUp(username, pwd, email)}
      >
        {({
            setFieldValue,
            submitForm,
            values,
            errors,
            touched,
          }) => {
          const passwordRequirements =
            [
              {
                id: 0, label: 'Contains at least 8 characters',
                condition: (values.pwd.length > 8),
              },
              {
                id: 1, label: 'Contains numbers',
                condition: (/\d/.test(values.pwd)),
              },
              {
                id: 2, label: 'Contains special character',
                condition: (/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
                  .test(values.pwd)),
              },
              {
                id: 3, label: 'Contains uppercase letters',
                condition: (values.pwd !== values.pwd.toLowerCase()),
              },
              {
                id: 4, label: 'Contains lowercase letters',
                condition: (values.pwd !== values.pwd.toUpperCase()),
              },
            ];
          return (
            <>
              <Input id="email" type="email" placeholder="Email"
                     isInvalid={!!errors.email && !!touched.email}
                     onChange={event =>
                       setFieldValue('email', event.target.value)} />
              {errors.email && touched.email && (
                <FieldError>* {errors.email}</FieldError>
              )}
              <Input id="username" type="text" placeholder="Username"
                     isInvalid={!!errors.username && !!touched.username}
                     onChange={event =>
                       setFieldValue('username', event.target.value)} />
              {errors.username && touched.username && (
                <FieldError>* {errors.username}</FieldError>
              )}
              <Input id="password" type="password" placeholder="Password"
                     isInvalid={!!errors.pwd && !!touched.pwd}
                     onChange={event =>
                       setFieldValue('pwd', event.target.value)} />
              {errors.pwd && touched.pwd && (
                <FieldError>* {errors.pwd}</FieldError>
              )}
              <LoginButton
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  if (values.username && values.email && values.pwd &&
                    !passwordRequirements.every((el) => el.condition)) {
                    return;
                  }
                  return submitForm();
                }}
                disabled={signUpState.status === 'IN_PROGRESS'}>
                {signUpState.status === 'IN_PROGRESS' ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      color='white'
                      style={{ marginRight: 10, marginBottom: 5 }}
                    />
                    Loading...
                  </>
                ) : (
                  'Sign Up'
                )}
              </LoginButton>
              {(signUpState.status === 'REJECTED') ? (
                (signUpState.error.message === 'Too many requests') ? (
                  <Error>
                    We’re experiencing a ton of requests right now.
                    Please try again in a moment.
                  </Error>
                ) : (
                  <Error>{signUpState.error.message}</Error>
                )
              ) : ''}
              <PasswordRequirements>
                {values.pwd && passwordRequirements.map((el) => (
                  <div
                    key={el.id}
                    style={{ color: `${el.condition ? '#49b653' : 'red'}` }}>
                    <FontAwesomeIcon
                      icon={el.condition ? faCheckCircle : faTimesCircle}
                      style={{ marginRight: 5 }} />
                    {el.label}
                  </div>
                ))}
              </PasswordRequirements>
            </>
          );
        }}
      </Formik>
      <OrLabel />
      <LoginLink to='/auth/log-in'>
        Already have an account?
      </LoginLink>
    </Container>
  );
};

const PasswordRequirements = styled.div`
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
`;

const Error = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

const FieldError = styled(Error)`
  margin-top: -10px;
  margin-bottom: 5px;
`;

const Container = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  width: 100%;
`;

const LoginButton = styled.button`
  border: none;
  background-image: linear-gradient(to left, #62451f, #b17e33);
  color: #ffffff;
  padding: 2px;
  font-size: 24px;
  font-weight: 300;
  margin-top: 5px;
  cursor: pointer;

  &:hover, &:focus {
    background-image: linear-gradient(to left, #b17e33, #d2a75d);
    outline: none;
  }
`;

const Input = styled.input<{ isInvalid: boolean }>`
  border: none;
  padding: 3px 10px;
  outline: ${({ isInvalid }) => isInvalid ? '1px solid red' : 'none'};
  background-color: #151515;
  color: white;
  margin-bottom: 14px;

  &::placeholder {
    color: white;
  }

  &:active, &:focus {
    outline: 1px solid white;
    background: #222222;
    color: white;
    border: none;
    font-size: 16px;
  }
`;

const OrLabel = styled.div`
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;

  &::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 25%;
    border-bottom: 1px solid #484c5c;
    width: 50%;
  }

  &::after {
    content: "OR";
    position: absolute;
    top: -12px;
    left: 45%;
    padding: 0 10px;
    background: #222222;

    @media(max-width: ${Breakpoints.iphone5}px) {
      left: 41%;
    }
  }
`;

const LoginLink = styled(Link)`
  color: white;

  &:first-of-type {
    margin-bottom: 10px;
  }

  &:hover, &:focus {
    color: white;
    text-decoration: underline;
    outline: none;
  }
`;

export default SignUpForm;

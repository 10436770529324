import { ChangeEvent, useCallback, useState } from 'react';

export default function useInput<
    TElement extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement
>(initialValue: string | (() => string)) {
    const [value, setValueRaw] = useState(initialValue);
    const setValue = useCallback((e: ChangeEvent<TElement>) => {
        setValueRaw(e.target.value);
    }, []);

    return [value, setValue, setValueRaw] as const;
}

import instance from '../instance';

export interface RollReward {
  rewardId_variantId: string;
  rewardTitle: string;
  rewardImage: string;
  variantTitle: string;
  invoiceUrl: string;
  price: number;
  variantId: string;
  stockToGive: number;
  chance: number;
  totalStock: number;
}

export async function executeGoldenKeyRoll() {
  return instance.post<{
    reward: Omit<RollReward, 'variantId' | 'stockToGive' | 'chance' | 'totalStock'>,
    isRewardReceived: boolean
  }>('/golden-keys');
}

export async function getRollRewards() {
  return instance.get<{ rewards: RollReward[] }>('/golden-keys/rewards');
}

import React, { useCallback, useEffect, useMemo } from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

import { useAPI } from '../../../utils/hooks/useRequest';
import api from '../../../api';
import BlurOverlay from '../../../components/BlurOverlay';
import Loader from '../../../components/Loader';
import { useChanged } from '../../../utils/hooks/useChanged';
import type { DowntimeItem } from '../../../api/downtime';

interface Props {
  downtime: DowntimeItem;
  onClose: () => void;
  onDeleted: (downtimeItem: DowntimeItem) => void;
}

const DeleteDowntimeItemPopup: React.FC<Props> = ({
  downtime,
  onClose,
  onDeleted,
}) => {
  const { state: deleteDowntimeItemState, fetch: deleteDowntimeItem } = useAPI(
    api.downtime.deleteItem,
  );
  const deleteDowntimeItemStateChanged = useChanged(
    deleteDowntimeItemState.status,
  );

  const handleConfirm = useCallback(() => {
    deleteDowntimeItem(downtime.id);
  }, [deleteDowntimeItem, downtime]);

  useEffect(() => {
    if (
      deleteDowntimeItemStateChanged &&
      deleteDowntimeItemState.status === 'FULFILLED'
    ) {
      onDeleted(downtime);
      onClose();
    }
  }, [
    deleteDowntimeItemStateChanged,
    deleteDowntimeItemState,
    onDeleted,
    downtime,
    onClose,
  ]);

  return (
    <>
      <Modal.Header>
        <b>Are you sure?</b>
      </Modal.Header>
      <Modal.Body>
        You are about to delete downtime item <b>&quot;{downtime.description}&quot;</b>.
        Once you click &quot;Yes&quot;, this cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          No
        </Button>
        <Button variant='primary' onClick={handleConfirm}>
          Yes
        </Button>
      </Modal.Footer>

      {deleteDowntimeItemState.status === 'IN_PROGRESS' && (
        <BlurOverlay>
          <Loader size='small' />
        </BlurOverlay>
      )}
    </>
  );
};

type WrapperProps = Omit<Props, 'downtime'> & {
  downtimeList: DowntimeItem[];
  parentUrl: string;
};

const DeleteDowntimePopupWrapper: React.FC<WrapperProps> = ({
  downtimeList,
  parentUrl,
  ...otherProps
}) => {
  const { onClose } = otherProps;

  const match = useRouteMatch<{ downtimeItemId: string }>();

  const downtimeItem = useMemo<DowntimeItem | undefined>(() => {
    if (!match) return undefined;
    return downtimeList.find((item) => item.id === match.params.downtimeItemId);
  }, [match, downtimeList]);

  const renderPopup = useCallback(
    (children?: React.ReactNode) => {
      return (
        <Modal
          show={!!match}
          onHide={onClose}
          backdrop='static'
          keyboard={false}
        >
          {children}
        </Modal>
      );
    },
    [match, onClose],
  );

  if (!match) {
    return renderPopup();
  }

  if (downtimeItem) {
    return renderPopup(
      <DeleteDowntimeItemPopup downtime={downtimeItem} {...otherProps} />,
    );
  }

  return <Redirect to={parentUrl} />;
};

export default DeleteDowntimePopupWrapper;

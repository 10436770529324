import styled from 'styled-components';

const BlurOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    display: flex;
`;

export default BlurOverlay;

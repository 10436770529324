import { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  Reducer,
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import {
  Actions,
  createActionCreators,
  createReducerFunction,
  ImmerReducer,
} from 'immer-reducer';
import { castDraft, Draft } from 'immer';

import instance from '../../api/instance';

export default function useRequest<TResponse>(config: AxiosRequestConfig) {
  const configRef = useRef(config);

  useDebugValue(
    configRef.current,
    (c) => `Request (${c.method ?? ''} ${c.url ?? ''})`,
  );

  const actions = useMemo(() => createActions<TResponse>(), []);
  const reducer = useMemo(() => createReducer<TResponse>(), []);

  const [state, dispatch] = useReducer<
    Reducer<State<TResponse>, Actions<Type<RequestReducer<TResponse>>>>
  >(reducer, initialState);

  const perform = useCallback(
    (
      data?: unknown,
      { removeOldData = false }: { removeOldData?: boolean } = {},
    ) => {
      (async () => {
        dispatch(actions.setInProgress({ removeOldData }));
        const curConfig = configRef.current;
        if (data) {
          curConfig.data = data;
        }
        const response = await instance.request<TResponse>(curConfig);
        dispatch(actions.setFulfilled(response.data));
      })().catch((e) => {
        console.error(e);
        dispatch(actions.setRejected(e));
      });
    },
    [actions, configRef],
  );

  useEffect(() => {
    configRef.current = config;
  }, [config]);

  return {
    state,
    perform: fetch,
  } as const;
}

export function useAPI<TResponse, TPayload = undefined>(
  apiCallback:
    | ((payload: TPayload) => AxiosPromise<TResponse>)
    | (() => AxiosPromise<TResponse>),
) {
  useDebugValue(apiCallback, (cb) => `API call (${cb.name})`);

  const actions = useMemo(() => createActions<TResponse>(), []);
  const reducer = useMemo(() => createReducer<TResponse>(), []);

  const [state, dispatch] = useReducer<
    Reducer<State<TResponse>, Actions<Type<RequestReducer<TResponse>>>>
  >(reducer, initialState);

  const fetch = useCallback(
    (
      payload: TPayload,
      { removeOldData = false }: { removeOldData?: boolean } = {},
    ) => {
      dispatch(actions.setInProgress({ removeOldData }));

      (async () => {
        const res = await apiCallback(payload);
        dispatch(actions.setFulfilled(res.data));
      })().catch((e) => {
        console.error(e);
        dispatch(actions.setRejected(e));
      });
    },
    [actions, apiCallback],
  );

  return {
    state,
    fetch,
    updateState: (
      cb: (draftState: Draft<State<TResponse>>) => State<TResponse> | void,
    ) => dispatch(actions.updateState(cb)),
  } as const;
}

export type State<T> =
  | {
      status: 'PENDING';
      data?: T;
      error?: Error;
    }
  | {
      status: 'IN_PROGRESS';
      data?: T;
      error?: Error;
    }
  | {
      status: 'FULFILLED';
      data: T;
      error?: Error;
    }
  | {
      status: 'REJECTED';
      data?: T;
      error: Error;
    };

const initialState: State<any> = {
  status: 'PENDING',
};

class RequestReducer<T> extends ImmerReducer<State<T>> {
  setInProgress({ removeOldData }: { removeOldData: boolean }) {
    this.draftState.status = 'IN_PROGRESS';
    if (removeOldData) {
      this.draftState.data = undefined;
    }
  }

  setFulfilled(data: T) {
    this.draftState.status = 'FULFILLED';
    this.draftState.data = castDraft(data);
  }

  setRejected(e: Error) {
    this.draftState.status = 'REJECTED';
    this.draftState.error = e;
  }

  updateState(cb: (draftState: Draft<State<T>>) => State<T> | void) {
    cb(this.draftState);
  }
}

interface Type<T> extends Function {
  new (...args: any[]): T;
  prototype: T;
}

function createReducer<T>() {
  return createReducerFunction<Type<RequestReducer<T>>>(
    RequestReducer,
    initialState,
  );
}

function createActions<T>() {
  return createActionCreators<Type<RequestReducer<T>>>(RequestReducer);
}

import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { reducer as authReducer } from './auth/AuthReducer';

const reducer = combineReducers({
    auth: authReducer,
});

const composeEnhancers = composeWithDevTools({});

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof reducer>;

export default store;

import instance from '../instance';

export interface Season {
    Season: number;
    Description: string;
    StartDateTime: number;
    EndDateTime: number;
}

export async function getSeasonsList() {
    return instance.get<{ seasons: Season[] }>('/seasons');
}

export async function getCurrentSeason() {
    return instance.get<{ season: Season | null }>('/seasons/current');
}

export async function createSeason(data: {
    id: Season['Season'];
    description: Season['Description'];
    startDateTime: Season['StartDateTime'];
    endDateTime: Season['EndDateTime'];
}) {
    return instance.post<{ season: Season; seasons: Season[] }>(
        `/seasons`,
        data,
    );
}

export async function updateSeason({
    id,
    data,
}: {
    id: Season['Season'];
    data: {
        description: Season['Description'];
        startDateTime: Season['StartDateTime'];
        endDateTime: Season['EndDateTime'];
    };
}) {
    return instance.post<{ season: Season }>(`/seasons/${id}`, data);
}

export async function deleteSeason(id: Season['Season']) {
    return instance.delete<{ status: 'ok' }>(`/seasons/${id}`);
}

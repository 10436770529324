import usePrev from './usePrev';
import { useDebugValue } from 'react';

export function useChanged<T>(value: T): boolean {
    const prevValue = usePrev(value);
    return prevValue !== value;
}

export function useChangedTo<T>(value: T, newValue: T): boolean {
    useDebugValue(newValue);
    const isChanged = useChanged(value);
    return isChanged && value === newValue;
}

import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Link, useRouteMatch, Route, useHistory } from 'react-router-dom';
import urlJoin from 'url-join';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import styled from 'styled-components';

import type { DowntimeItem } from '../../../api/downtime';
import { formatDateTime } from '../../../utils/format';
import api from '../../../api';
import { useAPI } from '../../../utils/hooks/useRequest';
import Loader from '../../../components/Loader';
import EditDowntimeItemPopupWrapper from './EditDowntimeItemPopup';
import DeleteDowntimePopupWrapper from './DeleteDowntimeItemPopup';

const Downtime: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const {
    state: listDowntimesState,
    fetch: listDowntimes,
    updateState: updateDowntimeListState,
  } = useAPI(api.downtime.listItems);

  const handleDowntimeItemUpdated = useCallback(
    (downtimeItem: DowntimeItem) => {
      updateDowntimeListState((draftState) => {
        if (!draftState.data) return;
        const itemIndex = draftState.data.downtimes.findIndex(
          (item) => item.id === downtimeItem.id,
        );
        if (itemIndex !== -1) {
          draftState.data.downtimes[itemIndex] = downtimeItem;
        }
      });
    },
    [updateDowntimeListState],
  );

  const handleNewsfeedChanged = useCallback(
    (newDowntimeList: DowntimeItem[]) => {
      updateDowntimeListState((draftState) => {
        if (!draftState.data) return;
        draftState.data.downtimes = newDowntimeList;
      });
    },
    [updateDowntimeListState],
  );

  const handleNewsfeedItemDeleted = useCallback(
    (downtimeItem: DowntimeItem) => {
      updateDowntimeListState((draftState) => {
        if (!draftState.data) return;
        const itemIndex = draftState.data.downtimes.findIndex(
          (item) => item.id === downtimeItem.id,
        );
        if (itemIndex !== -1) {
          draftState.data.downtimes.splice(itemIndex, 1);
        }
      });
    },
    [updateDowntimeListState],
  );

  const closePopup = useCallback(() => {
    history.push(match.url);
  }, [history, match]);

  const addNewItemButton = useMemo(
    () => (
      <Link to={urlJoin(match.url, '/new')}>
        <Button className='mt-2 mb-2' variant='success'>
          <FontAwesomeIcon icon={faPlus} /> Add new item
        </Button>
      </Link>
    ),
    [match.url],
  );

  useEffect(() => {
    listDowntimes(undefined);
  }, [listDowntimes]);

  if (!listDowntimesState.data) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        {addNewItemButton}
        <Card className='mt-2'>
          <DataTable<DowntimeItem>
            title='Downtime'
            theme='dark'
            columns={[
              { name: 'ID', selector: (d) => d.id, sortable: true },
              {
                name: 'Description',
                selector: (d) => d.description,
                sortable: true,
              },
              {
                name: 'Starts at',
                selector: (d) => d.startsAt,
                format: (d) => formatDateTime(d.startsAt),
                sortable: true,
              },
              {
                name: 'Ends at',
                selector: (d) => d.endsAt,
                format: (d) => formatDateTime(d.endsAt),
                sortable: true,
              },
              {
                name: '',
                right: true,
                cell: (item) => (
                  <>
                    <Link
                      to={urlJoin(match.url, `/${item.id}/edit`)}
                      title='Edit'
                    >
                      <TableButton variant='primary'>
                        <FontAwesomeIcon icon={faEdit} />
                      </TableButton>
                    </Link>
                    <Link
                      to={urlJoin(match.url, `/${item.id}/delete`)}
                      className='ml-2'
                      title='Delete'
                    >
                      <TableButton variant='danger'>
                        <FontAwesomeIcon icon={faTrash} />
                      </TableButton>
                    </Link>
                  </>
                ),
              },
            ]}
            data={listDowntimesState.data.downtimes}
          />
        </Card>
        {addNewItemButton}
      </Container>

      <Route path={urlJoin(match.path, '/:downtimeItemId/edit')}>
        <EditDowntimeItemPopupWrapper
          downtimeList={listDowntimesState.data.downtimes}
          onUpdated={handleDowntimeItemUpdated}
          onDeleted={handleNewsfeedItemDeleted}
          onClose={closePopup}
          parentUrl={match.url}
        />
      </Route>

      <Route path={urlJoin(match.path, '/new')}>
        <EditDowntimeItemPopupWrapper
          onCreated={handleNewsfeedChanged}
          onClose={closePopup}
          parentUrl={match.url}
        />
      </Route>

      <Route path={urlJoin(match.path, '/:downtimeItemId/delete')}>
        <DeleteDowntimePopupWrapper
          onClose={closePopup}
          onDeleted={handleNewsfeedItemDeleted}
          downtimeList={listDowntimesState.data.downtimes}
          parentUrl={match.url}
        />
      </Route>
    </>
  );
};

const TableButton = styled(Button)`
  white-space: nowrap;
`;

export default Downtime;

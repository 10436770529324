import React, { Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import urlJoin from 'url-join';

import store from './redux';
import AuthGuard from './utils/auth/AuthGuard';
import Loader from './components/Loader';
import AdminGuard from './utils/auth/AdminGuard';
import ErrorBoundary from './components/ErrorBoundary';
import TwitchCallback from './pages/TwitchCallback';
import Downtime from './pages/Admin/Downtime';
import GoldenKey from './pages/GoldenKey';
import Layout from './components/Layout';
import GoldenKeys from './pages/Admin/GoldenKeys';
import DownloadLinks from './pages/Admin/DownloadLinks';
import PlayersBan from './pages/Admin/PlayersBan';
import Auth from './pages/Auth';
import LogInForm from './pages/Auth/LogInForm';
import SignUpForm from './pages/Auth/SignUpForm';
import ResetPasswordForm from './pages/Auth/ResetPasswordForm';
import ConfirmEmailForm from './pages/Auth/ConfirmEmailForm';
import ChangePasswordForm from './pages/Auth/ChangePasswordForm';
import ResendGameKeys from './pages/Admin/ResendGameKeys';

const LoginCallback = React.lazy(() => import('./pages/LoginCallback'));

const Home = React.lazy(() => import('./pages/Home'));

const Admin = React.lazy(() => import('./pages/Admin'));

const RedeemRewards = React.lazy(() => import('./pages/RedeemRewards'));

const ShowcaseRewards = React.lazy(() => import('./pages/ShowcaseRewards'));

const PlayersAndTiers = React.lazy(() =>
  import('./pages/Admin/PlayersAndTiers'),
);

const Newsfeed = React.lazy(() => import('./pages/Admin/Newsfeed'));

const Seasons = React.lazy(() => import('./pages/Admin/Seasons'));

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>

          <Content>
            <Suspense fallback={<Loader />}><Layout>
              <Switch>
                <Route exact path='/login/cb'>
                  <LoginCallback />
                </Route>

                <Route path='/auth'>
                  {({ match }) =>
                    !!match && (
                      <Auth>
                        <Switch>
                          <Route path={urlJoin(match.url, '/log-in')}>
                            <LogInForm />
                          </Route>
                          <Route path={urlJoin(match.url, '/sign-up')}>
                            <SignUpForm />
                          </Route>
                          <Route path={urlJoin(match.url, '/reset-password')}>
                            <ResetPasswordForm />
                          </Route>
                          <Route path={urlJoin(match.url, '/confirm-email')}>
                            <ConfirmEmailForm />
                          </Route>
                          <Route path={urlJoin(match.url, '/change-password')}>
                            <ChangePasswordForm />
                          </Route>
                          <Redirect to={urlJoin(match.url, '/log-in')} />
                        </Switch>
                      </Auth>
                    )}
                </Route>
                <Route path='*'>
                  <AuthGuard>
                    <Switch>
                      <Route exact path='/'>
                        <Home />
                      </Route>

                      <Route exact path='/twitch/cb'>
                        <TwitchCallback />
                      </Route>

                      {/* <Route exact path='/goldenKey'>
                        <GoldenKey />
                      </Route> */}

                      <Route exact path='/rewards/redeem'>
                        <RedeemRewards />
                      </Route>

                      <Route exact path='/rewards/showcase'>
                        <ShowcaseRewards />
                      </Route>

                      <Route path='/admin'>
                        {({ match }) =>
                          !!match && (
                            <>

                              <AdminGuard>
                                <Admin>
                                  <Suspense fallback={<Loader />}>
                                    <Switch>
                                      <Route
                                        path={urlJoin(
                                          match.url,
                                          '/players-and-tiers',
                                        )}
                                      >
                                        <PlayersAndTiers />
                                      </Route>

                                      <Route
                                        path={urlJoin(match.url, '/seasons')}
                                      >
                                        <Seasons />
                                      </Route>

                                      <Route
                                        path={urlJoin(match.url, '/newsfeed')}
                                      >
                                        <Newsfeed />
                                      </Route>

                                      <Route
                                        path={urlJoin(match.url, '/downtime')}
                                      >
                                        <Downtime />
                                      </Route>

                                      <Route
                                        path={urlJoin(match.url, '/golden-keys')}
                                      >
                                        <GoldenKeys />
                                      </Route>

                                      <Route
                                        path={urlJoin(match.url, '/download-links')}
                                      >
                                        <DownloadLinks />
                                      </Route>
                                    <Route
                                      path={urlJoin(match.url, '/golden-keys')}
                                    >
                                      <GoldenKeys />
                                    </Route>

                                      <Route
                                        path={urlJoin(match.url, '/players-ban')}
                                      >
                                        <PlayersBan />
                                      </Route>

                                      <Route
                                        path={urlJoin(match.url, '/resend-game-keys')}
                                      >
                                        <ResendGameKeys />
                                      </Route>

                                      <Redirect
                                        to={urlJoin(
                                          match.url,
                                          '/players-and-tiers',
                                        )}
                                      />
                                    </Switch>
                                  </Suspense>
                                </Admin>
                              </AdminGuard>
                            </>
                          )
                        }
                      </Route>

                      <Redirect to='/' />
                    </Switch>
                  </AuthGuard>
                </Route>
              </Switch></Layout>
            </Suspense>
          </Content>

        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  );
};

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: rgb(25, 28, 38);
`;

export default hot(App);
